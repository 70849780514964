import React, {Component} from 'react';
import {ReadOnlyFieldList} from "./ReadOnlyFieldList";

export class CustomerShortInfoBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            fields: [
                {name: "id", label: "id"},
                {name: "type", label: "Тип", formatter: window.globalStorage.formatter.mapCustomerType},
                {name: "phone", label: "Телефон"},
                {name: "email", label: "e-mail"},
                {name: "contact_fio", label: "Контактное лицо"},
                {name: "company_name", label: "Компания"}
            ]
        };
    }

    componentDidMount() {
        window.globalStorage.restService.getEntity("customer/short_info", this.props.customerId)
            .then((data) => {this.setState({info: data})})
            .catch((err) => {window.globalStorage.error(err.message)});
    }

    render() {
        let footer = <a href={"/#/customer/edit/" + this.props.customerId}>Смотреть профиль клиента</a>;

        return <ReadOnlyFieldList caption="Клиент" fields={this.state.fields} entityObj={this.state.info} footer={footer} />;
    }
}
