import React, {Component} from 'react';
import {Button} from "primereact/button";
import {FormBlock} from "../Element/FormBlock/FormBlock";
import {ReadOnlyFieldList} from "../Element/ReadOnlyFieldList";

export class CustomEditPage extends Component {
    constructor(props) {
        super(props);

        this.template = 'base';

        let url = String(window.location.hash);
        let pieces = url.split("/");
        let entity_type = pieces[1];
        let entity_id = pieces[3];

        this.state = {
            entity_type: entity_type,
            entity_id: entity_id,
            caption: window.globalStorage.getEntityNameSingle(pieces[1]),
            entity_name: '',
            entity_obj: {},
            fieldDefs: this.getMainFormFields(),
            canSave: false
        };

        this.entity_obj_original = {};
        this.readOnlyFields = this.getReadOnlyFieldsDefs();

        this.onBack = this.onBack.bind(this);
        this.onSave = this.onSave.bind(this);
        this.showError = this.showError.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.onMainFieldChange = this.onMainFieldChange.bind(this);
    }

    componentDidMount() {
        window.globalStorage.restService.getEntity(this.state.entity_type, this.state.entity_id)
            .then((data) => {
                for (let n in data) {
                    if (null === data[n]) {
                        data[n] = ""; // set all nulls as an empty strings
                    }
                }

                data = this.processEntity(data);
                this.setEntityObjOriginal(data);
                this.setState({entity_obj: data});
            }).catch((err) => {
                this.showError(err.message)
            });
    }

    onBack() {
        window.location = "/#/" + this.state.entity_type + "/list";
    }

    isFormValid(){
        return true;
    }

    onConfirm(data,accept,reject) {
        accept();
    }

    onSave() {
        if(!this.isFormValid()) {
            window.globalStorage.error(<div>Введены неверные данные.<br/> Операция невозможна</div>);
            return;
        }

        let data = {};

        for (let n in this.state.fieldDefs) {
            if (this.state.fieldDefs.hasOwnProperty(n)) {
                if ('header' === this.state.fieldDefs[n].type) {
                    continue;
                }

                let field = this.state.fieldDefs[n];
                if (field.isChanged) {
                    data[field.name] = this.state.entity_obj[field.name];
                }
            }
        }

        data = this.beforeSavePreprocess(data);

        this.onConfirm(data,()=>{ //success
            window.globalStorage.restService.saveEntity(this.state.entity_type, this.state.entity_id, data)
                .then(() => {
                    // this.processEntity(this.state.entity_obj);
                    this.setEntityObjOriginal(this.state.entity_obj);
                    this.resetIsChangedFlags();
                    window.globalStorage.success("Измнения сохранены");
                }).catch(err => window.globalStorage.error(err.message));
        },()=>{ //fail
            window.globalStorage.warning('Изменения не были сохранены');
        });

    }

    showError(err) {
        window.globalStorage.error('Ошибка',err);
    }

    showSuccess(text) {
        window.globalStorage.success('ok',text);
    }

    setEntityObjOriginal(obj) {
        this.entity_obj_original = Object.assign({}, obj);
    }

    resetIsChangedFlags() {
        let fieldDefs = this.state.fieldDefs;
        for (let n in fieldDefs) {
            fieldDefs[n].isChanged = false;
        }

        this.setState({canSave: false, fieldDefs: fieldDefs});
    }

    onClearMainFormChanges() {
        this.resetIsChangedFlags();
        this.setState({entity_obj: Object.assign({}, this.entity_obj_original)});
    }

    getMainFormFields() {
        return [];
    }

    getReadOnlyFieldsDefs() {
        return [];
    }

    isMainFormChanged(entity_obj) {
        let isChanged = false;
        let fieldDefs = this.state.fieldDefs;

        for (let n in fieldDefs) {
            if (fieldDefs.hasOwnProperty(n)) {
                if ('header' === fieldDefs[n].type) {
                    continue;
                }

                let fldName = fieldDefs[n].name;
                if (this.entity_obj_original[fldName] !== entity_obj[fldName]) {
                    isChanged =  true;
                    fieldDefs[n].isChanged = true;
                } else {
                    fieldDefs[n].isChanged = false;
                }
            }
        }

        this.setState({fieldDefs: fieldDefs});

        return isChanged;
    }

    onMainFieldChange(fld, val) {
        let obj = this.state.entity_obj;
        obj[fld] = val;
        // console.log('onMainFieldChange: ',this.isMainFormChanged(obj));
        this.setState({entity_obj: obj, canSave: this.isMainFormChanged(obj)});
    }

    getReadOnlyFieldsContent() {
        return <ReadOnlyFieldList fields={this.readOnlyFields} entityObj={this.state.entity_obj} caption="Информация" />;
    }

    getRightPanelContent() {
        return <div className="card p-col-12 p-lg-7">
            {this.getReadOnlyFieldsContent()}

            {/*<div>
                <h2>Debug</h2>
                <pre ref={(el) => this.json_stub = el}></pre>
            </div>*/}
        </div>;
    }

    getCustomButtons() {
        return null;
    }

    getBottomContent() {
        return null;
    }

    processEntity(data) {
        this.setState({entity_name: data.login});
        return data;
    }

    beforeSavePreprocess(data) {
        return data;
    }

    render() {
        if(!this.template || this.template === 'base') {
            return this.renderBase();
        } else if(this.template === 'rows') {
            return this.renderRows();
        } else if(this.template === 'singleRow') {
            return this.renderSingleRow();
        }
    }

    renderBase(){
        return (<div>
            <div className="card card-w-title">
                <h1><strong>{this.state.caption}</strong> {this.state.entity_name} ({this.state.entity_id})</h1>

                <div className="eba-card-toolbar p-col-12">
                    <div className="eba-toolbar-subleft">
                        <Button label="Назад к списку" icon="pi pi-angle-left" iconPos="left" onClick={this.onBack} />
                        &nbsp;
                        <Button label="Сохранить" icon="pi pi-check-circle" iconPos="right" className="p-button-success" onClick={this.onSave} disabled={!this.state.canSave} />
                    </div>
                    <div className="eba-toolbar-subright">
                        {this.getCustomButtons()}
                    </div>
                </div>

                <div className="p-grid p-fluid p-col-12">
                    <div className="card p-col-12 p-lg-5">
                        <FormBlock
                            entityObject={this.state.entity_obj}
                            fieldDefs={this.state.fieldDefs}
                            onFieldChange={this.onMainFieldChange}
                        />

                        <hr/>

                        <Button label="Сбросить изменения" icon="pi pi-undo" iconPos="right" className="p-button-warning" onClick={() => this.onClearMainFormChanges()} disabled={!this.state.canSave} />
                    </div>

                    {this.getRightPanelContent()}
                </div>
            </div>

            {this.getBottomContent()}
        </div>);
    }

    renderRows(){
        return (<div>
            <div className="card card-w-title">
                <h1><strong>{this.state.caption}</strong> {this.state.entity_name} ({this.state.entity_id})</h1>

                <div className="eba-card-toolbar p-col-12">
                    <div className="eba-toolbar-subleft">
                        <Button label="Назад к списку" icon="pi pi-angle-left" iconPos="left" onClick={this.onBack} />
                        &nbsp;
                        <Button label="Сохранить" icon="pi pi-check-circle" iconPos="right" className="p-button-success" onClick={this.onSave} disabled={!this.state.canSave} />
                    </div>
                    <div className="eba-toolbar-subright">
                        {this.getCustomButtons()}
                    </div>
                </div>

                <div className="p-grid p-fluid p-col-12">
                    <div className="card p-col-12">
                        <FormBlock
                            entityObject={this.state.entity_obj}
                            fieldDefs={this.state.fieldDefs}
                            onFieldChange={this.onMainFieldChange}
                        />

                        <hr/>

                        <Button label="Сбросить изменения" icon="pi pi-undo" iconPos="right" className="p-button-warning" onClick={() => this.onClearMainFormChanges()} disabled={!this.state.canSave} />
                    </div>

                    <div className="card p-col-12">
                        {this.getRightPanelContent()}
                    </div>
                </div>
            </div>

            {this.getBottomContent()}
        </div>);
    }

    renderSingleRow(){
        return (<div>
            <div className="card card-w-title">
                <h1><strong>{this.state.caption}</strong> {this.state.entity_name} ({this.state.entity_id})</h1>

                <div className="eba-card-toolbar p-col-12">
                    <div className="eba-toolbar-subleft">
                        <Button label="Назад к списку" icon="pi pi-angle-left" iconPos="left" onClick={this.onBack} />
                        &nbsp;
                        <Button label="Сохранить" icon="pi pi-check-circle" iconPos="right" className="p-button-success" onClick={this.onSave} disabled={!this.state.canSave} />
                    </div>
                    <div className="eba-toolbar-subright">
                        {this.getCustomButtons()}
                    </div>
                </div>

                <div className="p-grid p-fluid p-col-12">
                    <div className="card p-col-12 p-lg-3 p-md-6">
                        <FormBlock
                            entityObject={this.state.entity_obj}
                            fieldDefs={this.state.fieldDefs}
                            onFieldChange={this.onMainFieldChange}
                        />

                        <hr/>

                        <Button label="Сбросить изменения" icon="pi pi-undo" iconPos="right" className="p-button-warning" onClick={() => this.onClearMainFormChanges()} disabled={!this.state.canSave} />
                    </div>

                    {this.getRightPanelContent()}
                </div>
            </div>

            {this.getBottomContent()}
        </div>);
    }
}
