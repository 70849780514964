import React, {Component} from 'react';
import {TabPanel, TabView} from "primereact/tabview";
import {CourierList} from "./CourierList";

export class CourierListNew extends Component
{
    render() {
        return <TabView>
            <TabPanel header="Новые" leftIcon="pi pi-list">
                <CourierList statusFilter="new" statusCaption="новые"/>
            </TabPanel>
        </TabView>
    }
}
