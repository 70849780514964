import React, {Component} from 'react';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";

export class FastSearchBlock extends Component {
    onSearchKeyPress(ev) {
        if (13 === ev.charCode) {
            this.onFastSearchDelivery();
        }
    }

    onFastSearchDelivery() {
        let value = this.inputFastSearchDelivery.element.value;
        if (value.length < 1) {
            return;
        }

        let id = Number(value);
        window.globalStorage.restService.isDeliveryExists(id)
            .then((data)=>{
                if (data.exists) {
                    window.location = "/#/delivery/edit/" + id;
                } else {
                    window.globalStorage.error("Заявка с номером " + id + " не найдена");
                }
            }).catch((err) => {window.globalStorage.error(err.message)});
    }

    render() {
        return <div className="overview-box sales">
            <i className="overview-icon pi pi-tag"/>
            <span className="p-inputgroup" style={{marginRight: '64px', marginBottom: '3px'}}>
                <InputText ref={(el) => this.inputFastSearchDelivery = el} onKeyPress={(ev) => this.onSearchKeyPress(ev)} />
                <Button icon="pi pi-search" onClick={()=>{this.onFastSearchDelivery()}} />
            </span>
            <div className="overview-subinfo">
                Быстрый поиск по номеру заявки
            </div>
        </div>
    }
}
