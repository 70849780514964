import React from 'react';
import {CustomEditPage} from "./CustomEditPage";
import {OrderListByCustomer} from "../EntityList/OrderListByCustomer";
import {CustomerNoteBlock} from "../Element/CustomerNoteBlock";

export class CustomerEditPage extends CustomEditPage {
    getMainFormFields() {
        return [
            {name: "login", label: "Логин", type: "string", isChanged: false},
            {name: "name", label: "Имя", type: "string", isChanged: false},
            {name: "surname", label: "Фамилия", type: "string", isChanged: false},
            {name: "email", label: "e-mail", type: "string", isChanged: false},
            {name: "phone", label: "Телефон", type: "string", isChanged: false},
            {name: "blocked", label: "Заблокирован", type: "boolean", isChanged: false},

            {name: "legal", label: "Реквизиты", type: "header", isChanged: false},
            {name: "company_name", label: "Компания", type: "string", isChanged: false},
            {name: "inn", label: "ИНН", type: "string", isChanged: false},
            {name: "kpp", label: "КПП", type: "string", isChanged: false},
            {name: "ogrn", label: "ОГРН", type: "string", isChanged: false},
            {name: "legal_address", label: "Адрес юридический", type: "string", isChanged: false},
            {name: "actual_address", label: "Адрес фактический", type: "string", isChanged: false},
            {name: "contact_person", label: "Контактное лицо", type: "string", isChanged: false},

            {name: "bank", label: "Банковские реквизиты", type: "header", isChanged: false},
            {name: "bank_name", label: "Наименование банка", type: "string", isChanged: false},
            {name: "payment_account", label: "Р/счёт", type: "string", isChanged: false},
            {name: "bik", label: "БИК", type: "string", isChanged: false},
            {name: "correspondent_account", label: "Кор/счёт", type: "string", isChanged: false},
            {
                name: "discount_percent",
                label: "Процент скидки",
                type: "select",
                isChanged: false,
                selectOptions: [0,5,10,15,20,25].map((item)=>{
                    return {value: item.toFixed(0), label: item+'%'}
                }),
                className: 'select-single-row'
            },
            {
                name: "loyality",
                label: "Лояльность",
                type: "select",
                isChanged: false,
                selectOptions: [1,2,3,4,5,6,7,8,9,10].map((item)=>{
                    return {value: item, label: item}
                }),
                className: 'select-single-row'
            },
            {name: "source_id", label: "Источник", type: "dropdown", isChanged: false, placeholder: 'Выберите источник',
                selectOptions: () => this.getSourceOptions(),
                allowAddElement: true
            },
            {name: "responsible_id", label: "Ответственный", type: "dropdown", isChanged: false, placeholder: 'Выберите ответственного',
                selectOptions: () => this.getResponsibleOptions()
            },
            {name: "referer", label: "Партнер", type: "string", isChanged: false, maxLength: 32},
/*
            {name: "comment", label: "Комментарий", type: "textarea", isChanged: false,
                onFieldChange: (e) => {
                    console.log('>>>>>',e);
                    if(e.target.value.length < 400) {
                        this.setState({comment: e.target.value});
                    }
                }}
*/
        ];
    }

    getSourceOptions() {
        return this.state?.sources?.map((item)=>{
            return {value: item.id, label: item.name}
        })
    }

    getResponsibleOptions() {
        return this.state?.responsible?.map((item)=>{
            return {value: item.id, label: item.login}
        })
    }

    mapType(type) {
        const typeMapping = {
            0: "физическое лицо",
            1: "юридическое лицо"
        };

        if (null === type || !typeMapping.hasOwnProperty(type)) {
            return null;
        }

        return typeMapping[type];
    }

    mapPayType(type) {
        const typeMapping = {
            0: "наличные",
            1: "безналичный расчёт"
        };

        if (null === type || !typeMapping.hasOwnProperty(type)) {
            return null;
        }

        return typeMapping[type];
    }

    getReadOnlyFieldsDefs() {
        return [
            {name: "id", label: "id"},
            {name: "created_on", label: "Создано"},
            {name: "update_date", label: "Обновлено"},
            {name: "type", label: "Тип", formatter: this.mapType},
            {name: "type", label: "Оплата", formatter: this.mapPayType},
            {name: "balance", label: "Баланс", formatter: window.globalStorage.formatter.formatMoneyInCents},
            {name: "reserve", label: "Резерв", formatter: window.globalStorage.formatter.formatMoneyInCents}
        ];
    }

    getRightPanelContent() {
        return <div className="card p-col-12 p-lg-7">
            {this.getReadOnlyFieldsContent()}

            {/*<div className="p-col-12" style={{display: 'none'}}>
                <h2>Debug</h2>
                <pre ref={(el) => this.json_stub = el}></pre>
            </div>*/}
            <CustomerNoteBlock entityId={this.state.entity_id}/>
        </div>;
    }

    getBottomContent() {
        return <OrderListByCustomer itemsCount={10} customerId={this.state.entity_id} statusFilter="bycustomer" />
    }

    componentDidMount() {
        super.componentDidMount();
        window.globalStorage.restService.get('/v1/customer/get-sources').then((data)=>{
            this.setState({sources: data});
        })
        window.globalStorage.restService.get('/v1/user/list').then((data)=>{
            this.setState({responsible: data});
        })
    }
}
