import React, {Component} from 'react';
import {Button} from "primereact/button";
import {DeliveryAddressEditPanel} from "./DeliveryAddressEditPanel";
import {FormatterService} from "../../../service/FormatterService";
import misc from "../../../service/Misc";

export class DeliveryAddressItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            showEdit: false
        };

        this.statusMapping = {
            0: "выехал",
            1: "прибыл",
            2: "подпись",
            3: "спор"
        };

        this.startEdit = this.startEdit.bind(this);
        this.onSave = this.onSave.bind(this);
        this.formatter = new FormatterService();
    }

    startEdit(e) {
        this.setState({showEdit: true});
    }

    mapRouteStatus(status) {
        if (null === status || !this.statusMapping.hasOwnProperty(status)) {
            return null;
        }

        return <strong>{this.statusMapping[status]}</strong>;
    }

    onSave(changedFields) {
        let p;

        let isNew = (0 === this.state.data.id);
        if (isNew) {
            changedFields.delivery = this.state.data.delivery;

            if (!changedFields.hasOwnProperty('arrival_date')) {
                changedFields.arrival_date = this.state.data.arrival_date;
            }
            if (!changedFields.hasOwnProperty('payment_here')) {
                changedFields.payment_here = this.state.data.payment_here;
            }

            p = window.globalStorage.restService.addEntity("delivery/address", changedFields);
        } else {
            p = window.globalStorage.restService.saveEntity("delivery/address", this.state.data.id, changedFields);
        }

        p.then((res) => {
            let newData = this.state.data;
            for (let n in changedFields) {
                newData[n] = changedFields[n];
            }

            if (isNew) {
                newData.id = res.delivery_address;
                this.props.onAddFinished();
            }

            newData['arrival_wait_to'] = misc.getArrivalWaitTo(newData['arrival_date'],newData['arrival_wait_time']);

            this.setState({showEdit: false, data: newData});
            this.editPanel.setState({entityObject: Object.assign({}, newData), entityObjectOriginal: Object.assign({}, newData)});
            this.editPanel.resetIsChangedFlags();
        }).catch((err) => window.globalStorage.error(err.message));
    }

    render() {
        let data = this.state.data;
        let internet_shop_price_formatted = data.internet_shop_price ? this.formatter.formatMoneyInCents(data.internet_shop_price) : null;

        return <div className={this.state.showEdit ? "p-grid p-flex eba-address-list-item eba-address-list-item-editing" : "p-grid p-flex eba-address-list-item"} onDoubleClick={this.startEdit}>
            <div className="p-col-12 p-lg-1 eba-address-cell">№&nbsp;{data.id}<br/>{this.mapRouteStatus(data.execution_status)}</div>
            <div className="p-col-12 p-lg-4 eba-address-cell">
                с <strong>{data.arrival_date}</strong> до <strong>{data.arrival_wait_to}</strong>
                <br/><br/>
                {data.address}<br/>{data.distance_to_metro} метров от <span className="eba-metro-label" style={{backgroundColor: "#" + data.metro_line_hex_color}}>м. {data.metro_station_name}</span><br/><br/>
                {data.contact_phone} ({data.contact_name})
            </div>
            <div className="p-col-12 p-lg-4 eba-address-cell">{data.comment}</div>
            <div className="p-col-12 p-lg-2 eba-address-cell">
                {data.internet_shop_id}<br/>{internet_shop_price_formatted}
            </div>
            <div className="p-col-12 p-lg-1 eba-address-cell">
                <div className="eba-cell-to-show-on-hover">
                    <Button icon="pi pi-pencil" tooltip="Редактировать" tooltipOptions={{position: "bottom"}} onClick={this.startEdit} disabled={this.state.showEdit} />
                    &nbsp;
                    <Button icon="pi pi-trash" className="p-button-danger" tooltip="Удалить" tooltipOptions={{position: "bottom"}} onClick={this.props.onDelete} />
                </div>
            </div>

            <DeliveryAddressEditPanel
                ref={el => this.editPanel = el}
                entityObject={this.state.data}
                visible={this.state.showEdit}
                onCancel={() => {this.setState({showEdit: false})}}
                onSave={this.onSave}
            />
        </div>
    }
}
