import React, {Component} from 'react';
import {InputText} from "primereact/inputtext";
import {Spinner} from "primereact/spinner";
import {ToggleButton} from "primereact/togglebutton";
import {Calendar} from "primereact/calendar";
import {ReactDadata} from "react-dadata";
import {SelectButton} from "primereact/selectbutton";
import {Dropdown} from "primereact/dropdown";
import {InputTextarea} from "primereact/inputtextarea";

export class GenericInputListItem extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleDChange = this.handleDChange.bind(this);
        this.handleDTChange = this.handleDTChange.bind(this);
        this.handleMoneyChange = this.handleMoneyChange.bind(this);
    }

    handleChange(e) {
        this.props.onChange(this.props.name, e.target.value);
    }

    handleAddressChange(a) {
        this.props.onChange(this.props.name, a.value);
    }

    handleDChange(e) {
        this.props.onChange(this.props.name, this.formatDate(e.target.value));
    }

    handleDTChange(e) {
        this.props.onChange(this.props.name, window.globalStorage.formatter.formatDateTime(e.target.value));
    }

    handleMoneyChange(e) {
        this.props.onChange(this.props.name, window.globalStorage.formatter.cleanupMoneyString(e.target.value));
    }

    formatDate(date) {
        if (null === date) {
            return null;
        }

        let month = String(date.getMonth()+1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');

        return date.getFullYear() + "-" + month + "-" + day;
    }

    getInput(type) {
        let className = this.props.className+(this.props.isChanged ? " eba-input-changed" : "");
        if(this.props.isInvalid) {
            className += " invalid-field";
        }

        switch (type) {
            case 'number':
                return <Spinner value={this.props.value} placeholder={this.props.label} onChange={this.handleChange} className={className} />;

            case 'boolean':
                className = this.props.isChanged ? "eba-toggle-input-changed" : "";
                return <ToggleButton checked={this.props.value} onChange={this.handleChange} className={className} />;

            case 'select':
                className = this.props.className+(this.props.isChanged ? " eba-toggle-input-changed" : "");
                return <SelectButton options={this.props.selectOptions} value={this.props.value} onChange={this.handleChange} className={className} />;

            case 'dropdown':
                className = this.props.className+(this.props.isChanged ? " eba-toggle-input-changed" : "");
                const options = typeof (this.props.selectOptions) === "function" ? this.props.selectOptions() : this.props.selectOptions;
                return <Dropdown options={options} value={this.props.value} onChange={this.handleChange} className={className} />;

            case 'address':
                return <ReactDadata
                    ref={el => this.addressField = el}
                    token="1f6ebbd97fcb23fbe28eda3849b7af31b1cbee90"
                    query={this.props.value}
                    placeholder={this.props.label}
                    className={className}
                    onChange={this.handleAddressChange}
                />;

            case 'datetime':
                let dtval = new Date(null === this.props.value ? 0 : this.props.value);

                return <Calendar
                    value={dtval}
                    placeholder={this.props.placeholder | this.props.label}
                    dateFormat="yy-mm-dd"
                    navigator={true}
                    showButtonBar={true}
                    showIcon={true}
                    showTime={true}
                    hourFormat="24"
                    className={className}
                    onChange={this.handleDTChange}
                />;

            case 'date':
                let dval = new Date(null === this.props.value ? 0 : this.props.value);

                return <Calendar
                    value={dval}
                    placeholder={this.props.label}
                    dateFormat="yy-mm-dd"
                    navigator={true}
                    showButtonBar={true}
                    showIcon={true}
                    showTime={false}
                    className={className}
                    onChange={this.handleDChange}
                />;

            case 'money':
                return <InputText value={this.props.value} placeholder={this.props.label} onChange={this.handleMoneyChange} className={className} />;

            case 'textarea':
                // <InputTextarea ref={el => this.noteTextarea = el} rows={5} placeholder="Текст заметки" autoResize={true} onChange={(e) => this.setState({note: e.target.value})} value={this.state.note} />
                return <InputTextarea
                    ref={el => this.noteTextarea = el}
                    value={this.props.value}
                    placeholder={this.props.label}
                    className={className}
                    rows={4}
                    onChange={this.handleChange}
                    />;

            default:
                return <InputText
                    value={this.props.value}
                    placeholder={this.props.label}
                    onChange={this.handleChange}
                    className={className}
                    readOnly={this.props.readOnly ? 'readonly' : null}
                    maxLength={this.props.maxLength}
                    /* style={style}*/
                />;
        }
    }

    render() {
        if ('header' === this.props.type) {
            return <h2>{this.props.label}</h2>;
        }

        return <li className={this.props.twoColumnsMode ? "eba-list-item-clear p-col-12 p-lg-6" : "eba-list-item-clear"}>
            <dt>{this.props.label}</dt>
            <dd>{this.getInput(this.props.type)}</dd>
        </li>;
    }
}
