import React, {Component} from 'react';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Button} from "primereact/button";
import {Growl} from "primereact/growl";

export default class Login extends Component {
	onLoginAttempt() {
		let loginLen = this.loginInput.element.value.length;
		let passLen = this.passwordInput.inputEl.value.length;

		if (3 > loginLen || 3 > passLen) {
			this.loginInput.element.classList.add('p-error');
			this.passwordInput.inputEl.classList.add('p-error');
			return;
		}

		window.globalStorage.restService.login(this.loginInput.element.value, this.passwordInput.inputEl.value)
			.then(function(data) {
				window.globalStorage.stat = data;
				window.globalStorage.setLoggedIn(true, false, true);
			}).catch((err) => {window.globalStorage.error(err)});
	}

	showError(errText) {
		let msg = {severity: 'error', summary: 'Ошибка', detail: errText.message};
		this.growl.show(msg);
	}

	passwordKeyPress(e) {
		if (13 === e.charCode) {
			this.onLoginAttempt();
		}
	}

	anyInputChange() {
		this.loginInput.element.classList.remove('p-error');
		this.passwordInput.inputEl.classList.remove('p-error');
	}

	render() {
		return <div className="login-body">
			<Growl ref={(el) => this.growl = el} />

			<div className="login-panel"></div>

			<div className="login-content">
				<img src="/assets/layout/images/easybox-logo-white.jpg" alt="EasyBox logo"/>

				<h1><span>Войти</span> в админку</h1>

				<div className="login-input-wrapper">
					<InputText ref={el => this.loginInput = el} placeholder="Логин" onInput={() => {this.anyInputChange()}} />
				</div>

				<div className="login-input-wrapper">
					<Password ref={el => this.passwordInput = el} placeholder="Пароль" feedback={false} onKeyPress={(e) => this.passwordKeyPress(e)} onInput={() => {this.anyInputChange()}} />
				</div>

				<Button label="Войти" onClick={() => {this.onLoginAttempt()}} />
			</div>
		</div>
	}
}
