import React from 'react';
import {OrderList} from "./OrderList";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

export class OrderListUnassigned extends OrderList {
    getTable() {
        return <DataTable
            ref={el => this.tbl = el}
            value={this.state.courierList}
            paginator={true}
            rows={this.state.filter.limit}
            first={this.state.filter.offset}
            totalRecords={this.state.countItems}
            alwaysShowPaginator={false}
            lazy={true}
            onPage={(ev) => this.onPage(ev)}
            onRowDoubleClick={(ev) => {this.onRowDblClick(ev)}}
            emptyMessage="Список пуст">

            <Column field="id" header="id" className="eba-id-column"/>
            <Column field="cargo_info" header="груз"/>
            <Column field="declared_price" body={this.formatMoneyInCents} header="объявленная ценность" className="eba-numeric-column" />
            <Column field="sum_customer_payment" body={this.formatMoneyInCents} header="цена для клиента" className="eba-numeric-column" />
            <Column field="candy_wrapper_bonus" header="баллы" className="eba-numeric-column" style={{width: '80px'}} body={this.formatMoneyInCents} />
            <Column field="created_on" header="создан" style={{width: '122px'}} />
            <Column field="min_arrival_date" header="время первой встречи" style={{width: '122px'}} />

        </DataTable>
    }
}
