import React, {Component} from 'react';
import {DocumentItem} from "./DocumentItem";
import {Button} from "primereact/button";
import ImageView from "../../../service/ImageView";
import {Row} from "primereact/row";

export class CourierDocuments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            docTypes: {
                passport1: {code: 'passport1', label: "Паспорт фото 1", meta: {}, isChanged: false},
                passport2: {code: 'passport2', label: "Паспорт фото 2", meta: {}, isChanged: false},
                driver_licence1: {code: 'driver_licence1', label: "Водительские права фото 1", meta: {}, isChanged: false},
                driver_licence2: {code: 'driver_licence2', label: "Водительские права фото 2", meta: {}, isChanged: false},
                selfie_with_passport: {code: 'selfie_with_passport', label: "Селфи с паспортом", meta: {}, isChanged: false},
                full_height: {code: 'full_height', label: "Фото в полный рост", meta: {}, isChanged: false},
                snils: {code: 'snils', label: "СНИЛС фото", meta: {}, isChanged: false},
                inn: {code: 'inn', label: "ИНН фото", meta: {}, isChanged: false}
            },
            loaded: false,
            canSave: false,
            imgUrl: ''
        };

        this.onChange = this.onChange.bind(this);
        this.clearChanges = this.clearChanges.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        const that = this;

        window.globalStorage.restService.getDocumentsMeta(this.props.courierId)
            .then(function(data) {
                for (let n in that.state.docTypes) {
                    let typeItem = that.state.docTypes[n];

                    let field = typeItem.code + "_exists";
                    typeItem.meta.exists = data[field];

                    field = typeItem.code + "_verify_status";
                    typeItem.meta.verify_status = data[field];
                    typeItem.meta.verify_status_original = typeItem.meta.verify_status;

                    field = typeItem.code + "_upload_on";
                    typeItem.meta.upload_on = data[field];
                }

                that.setState({docTypes: that.state.docTypes, loaded: true});
            }).catch((err) => {
                if ("Not found" !== err.message) {
                    // this.props.showError(err.message);
                    window.globalStorage.error(err.message);
                }
            });
    }

    onChange(a) {
        let docTypes = this.state.docTypes;
        docTypes[a.target.name].meta.verify_status = (null === a.value) ? docTypes[a.target.name].meta.verify_status_original : a.value;

        docTypes[a.target.name].isChanged = (docTypes[a.target.name].meta.verify_status !== docTypes[a.target.name].meta.verify_status_original);

        this.setState({docTypes: docTypes});
        this.checkCanSave();
    }

    checkCanSave() {
        for (let n in this.state.docTypes) {
            if (this.state.docTypes[n].isChanged) {
                this.setState({canSave: true});
                return;
            }
        }

        this.setState({canSave: false});
    }

    clearChanges() {
        let docTypes = this.state.docTypes;
        for (let n in docTypes) {
            let typeItem = docTypes[n];

            typeItem.meta.verify_status = typeItem.meta.verify_status_original;
            typeItem.isChanged = false;
        }

        this.setState({docTypes: docTypes, canSave: false});
    }

    markChangesSaved() {
        let docTypes = this.state.docTypes;
        for (let n in docTypes) {
            let typeItem = docTypes[n];

            typeItem.meta.verify_status_original = typeItem.meta.verify_status;
            typeItem.isChanged = false;
        }

        this.setState({docTypes: docTypes, canSave: false});
    }

    onSave() {
        let changed = {};

        for (let n in this.state.docTypes) {
            if (this.state.docTypes[n].isChanged) {
                changed[n + "_verify_status"] = this.state.docTypes[n].meta.verify_status
            }
        }

        window.globalStorage.restService.saveEntity("courier/document/list", this.props.courierId, changed)
            .then(() => {
                this.markChangesSaved();
                // this.props.showSuccess("Измнения сохранены");
                window.globalStorage.success("Измнения сохранены");
            }).catch((err) => {
                // this.props.showError(err.message)
                window.globalStorage.error(err.message);
            });
    }

    enlargeItem(imgUrl,doc){
        this.setState({imgUrl:imgUrl})
    }

    render() {
        if (!this.state.loaded) {
            return <div className="card card-w-title p-col-12">
                <h2>Документы</h2>
                <div>Нет данных</div>
            </div>
        }

        let items = Object.values(this.state.docTypes).map((item) => <DocumentItem
            courierId={this.props.courierId}
            code={item.code}
            label={item.label}
            key={item.code}
            data={item.meta}
            onChange={this.onChange}
            onEnlarge={(e)=>this.enlargeItem(e,item)}
            isChanged={item.isChanged}
            className="docs-item"
        />);

        return <div className="card card-w-title p-col-12">
            <ImageView url={this.state.imgUrl} onClose={()=>this.setState({imgUrl:''})}/>
            <h2>Документы</h2>

            <Row>
                <div className="eba-card-toolbar p-col-12">
                    <div className="eba-toolbar-subleft">
                        <Button label="Сбросить изменения" icon="pi pi-undo" iconPos="right" className="p-button-warning" onClick={this.clearChanges} disabled={!this.state.canSave} />
                        &nbsp;
                        <Button label="Сохранить" icon="pi pi-check-circle" iconPos="right" className="p-button-success" onClick={this.onSave} disabled={!this.state.canSave} />
                    </div>
                </div>
            </Row>

            <Row>
                <div className="eba-docs-envelope docs-container">
                    {items}
                </div>
            </Row>
        </div>
    }
}
