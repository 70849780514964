import React, {Component} from 'react';
import {GenericInputListItem} from "./GenericInputListItem";

export class FormBlock extends Component {
    render() {
        if (!this.props.entityObject) {
            return <div>нет данных</div>;
        }

        let fields = this.props.fieldDefs.map((fld) => {
            const field = this.props.entityObject[fld.name];
            let val = (field !== undefined && field !== null) ? field : ('boolean' === fld.type ? false : "");

            let selectOptions = ('undefined' !== typeof(fld.selectOptions)) ? fld.selectOptions : null;

            const isInvalid = fld.isInvalid !== undefined ? fld.isInvalid : false;
            // const style = fld.style !== undefined ? fld.style : '';
            const readOnly = fld.readOnly !== undefined ? fld.readOnly : false;
            const className = fld.className !== undefined ? fld.className : '';
            const maxLength = fld.maxLength !== undefined ? fld.maxLength : '';

            return <GenericInputListItem
                key={fld.name}
                label={fld.label}
                name={fld.name}
                value={val}
                type={fld.type}
                onChange={this.props.onFieldChange}
                isChanged={fld.isChanged}
                twoColumnsMode={this.props.twoColumnsMode}
                selectOptions={selectOptions}
                readOnly={readOnly}
                // style={style}
                isInvalid={isInvalid}
                className={className}
                maxLength={maxLength}
            />;
        });

        return <dl className={this.props.twoColumnsMode ? "p-grid" : null}>{fields}</dl>;
    }
}
