import React, {Component} from 'react';

export class ReadOnlyFieldList extends Component {
    render() {

        let fields = this.props.fields.map((fld) => {
            let val = this.props.entityObj[fld.name];
            let key = fld.name+":"+fld.label;

            if ("function" === typeof(fld.formatter)) {
                val = fld.formatter(val);
            }

            return <li key={key} className="eba-list-item-tab">
                <dt>{fld.label}:</dt>
                <dd>{val}</dd>
            </li>;
        });

        return <div className="p-col-12">
            <h2 style={{display:'flex', justifyContent:'space-between'}}>
                <span>{this.props.caption}</span>
                <span>{this.props.actions}</span>
            </h2>
            <dl>{fields}</dl>
            {this.props.footer}
        </div>;
    }
}
