import React from 'react';
import {BaseListView} from "./BaseListView";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {SearchBlock} from "../Element/SearchBlock";
import {DateRangeBlock} from "../Element/DateRangeBlock";

export class CustomerList extends BaseListView {
    constructor(props) {
        super(props);

        this.entity.type = "customer";
        this.entity.caption = "Клиенты";

        this.mapType = this.mapType.bind(this);
    }

    onReloadListDone() {
        this.initExcel();
    }

    excelPreformat(data){
        return [{
            columns: [
                {title:"id", width: {wpx: 80}},
                {title:"компания", width: {wch: 20}},
                {title:"логин", width: {wch: 25}},
                {title:"ИНН", width: {wch: 14}},
                {title:"контакт", width: {wch: 20}},
                {title:"заказов", width: {wch: 7}},
                {title:"отмененных", width: {wch: 7}},
                {title:"телефон", width: {wch: 14}},
                {title:"email", width: {wch: 14}},
                {title:"Ответственный", width: {wch: 30}},
                {title:"тип", width: {wch: 3}},
                // {title:"подтверждён", width: {wch: 11}},
                // {title:"заблокирован", width: {wch: 11}},
                {title:"партнер", width: {wch: 10}},
                {title:"источник", width: {wch: 10}},
                {title:"баланс", width: {wch: 10}},
                {title:"лояльность", width: {wch: 3}},
                {title:"создан", width: {wch: 20}},
                // {title:"обновлён", width: {wch: 20}},
            ],
            data: data.map( (item) => {
                return [
                    {value: item.id.toString()},
                    {value: item.company_name || ''},
                    {value: item.login || ''},
                    {value: item.inn || ''},
                    {value: item.contact_person || ''},
                    {value: parseInt(item.count_orders || '0')},
                    {value: parseInt(item.count_cancelled || '0')},
                    {value: item.phone || ''},
                    {value: item.email || ''},
                    {value: item.responsible || ''},
                    // {value: item.fio || ''},
                    {value: item.type ? 'юр' : 'физ'},
                    {value: item.referer || ''},
                    {value: item.source || ''},
                    // {value: item.actual_address || ''},
                    // {value: item.confirmed ? 'Да' : 'Нет'},
                    // {value: item.blocked ? 'Да' : 'Нет'},
                    {value: parseFloat(this.formatter.formatMoneyInCents(item.balance || '0')), numFmt: "0.00%"},
                    {value: item.loyality || ''},
                    {value: item.created_on || ''},
                    // {value: item.update_date || ''},
                ]
            })
        }];
    }

    mapType(row, field) {
        return window.globalStorage.formatter.mapCustomerType(row[field.field]);
    }

    onClientDate(from, to) {
        this.setFilter({
            c_date_from: from ? this.formatDate(from) + " 00:00:00" : "",
            c_date_to: to ? this.formatDate(to) + " 23:59:59" : ""
        });

        this.reloadList();
    }

    DateClean1() {
        this.DateRangeBlock2.onDateCleanRaw()
    }

    DateClean2() {
        this.DateRangeBlock1.onDateCleanRaw()
    }

    filters() {
        return <div style={{display: 'flex', flexDirection: 'row', alignContent: 'flex-end'}}>
                <label style={{display: 'flex', flexDirection: 'column', justifyItems: 'flex-end'}}>
                    Поиск по подстроке:
                    <SearchBlock onReloadList={(text) => this.onSearch(text)} />
                </label>
                <label style={{display: 'flex', flexDirection: 'column'}}>
                    По дате создания:
                    <DateRangeBlock
                        ref={el => this.DateRangeBlock1 = el}
                        dateRangeFrom={this.state.filter.dateRangeFrom}
                        onReloadList={(from, to) => this.onFilterDate(from, to)}
                        onChange={(d)=>{
                            this.DateClean1();
                        }}
                    />
                </label>
                <label style={{display: 'flex', flexDirection: 'column'}}>
                    По клиентам:
                    <DateRangeBlock
                        ref={el => this.DateRangeBlock2 = el}
                        dateRangeFrom={this.state.filter.dateRangeFromClient}
                        onReloadList={(from, to) => this.onClientDate(from, to)}
                        onChange={(d)=>{
                            this.DateClean2();
                        }}
                    />
                </label>

        </div>;
    }

    getTable() {
        return <DataTable
            ref={el => this.tbl = el}
            value={this.state.courierList}
            paginator={true}
            rows={this.state.filter.limit}
            first={this.state.filter.offset}
            totalRecords={this.state.countItems}
            alwaysShowPaginator={false}
            lazy={true}
            onPage={(ev) => this.onPage(ev)}
            onRowDoubleClick={(ev) => {this.onRowDblClick(ev)}}
            emptyMessage="Список пуст">

            <Column field="id" header="id" className="eba-id-column"/>
            <Column field="company_name" header="компания" style={{wordBreak: "break-all"}}/>
            <Column field="login" header="логин" style={{wordBreak: "break-all"}}/>
            <Column field="inn" header="ИНН" style={{width: '100px', wordBreak: "break-all"}} />
            <Column field="contact_person" header="контакт" />
            <Column field="count_orders" header="выполненных" />
            <Column field="count_cancelled" header="отмененных" />
            <Column field="phone" header="телефон" style={{wordBreak: "break-all"}} />
            <Column field="email" header="email" style={{wordBreak: "break-all"}}/>
            <Column field="responsible" header="ответственный" style={{wordBreak: "break-all"}} />
            {/*<Column field="fio" header="ФИО" />*/}

            {/*<Column field="actual_address" header="факт. адрес" />*/}
            <Column field="type" header="тип" style={{width: '50px'}} body={this.mapType} />
            {/*<Column field="confirmed" header="подтверждён" style={{width: '122px'}} body={this.formatBooleanField} />*/}
            {/*<Column field="blocked" header="заблокирован" style={{width: '122px'}} body={this.formatBooleanField} />*/}
            <Column field="referer" header="партнер" style={{width: '122px', wordBreak: "break-all"}} />
            <Column field="source" header="источник" style={{width: '122px', wordBreak: "break-all"}} />
            <Column field="balance" header="баланс" body={this.formatMoneyInCents} className="eba-numeric-column" />
            <Column field="loyality" header="лояльность" style={{width: '122px'}} />
            <Column field="created_on" header="создан" style={{width: '122px'}} />
            {/*<Column field="update_date" header="обновлён" style={{width:'122px'}} />*/}

        </DataTable>
    }
}
