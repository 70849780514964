export default (
    ()=>{
        let instance;

        return ()=>{
            if(!instance) {
                instance = {
                    // host: 'http://178.159.249.179'
                    host: 'https://mn.myeasybox.ru'
                }
            }
            return instance;
        }
    }
)();
