import React, {Component} from 'react';
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";

export class DateRangeBlock extends Component {
    constructor(props) {
        super(props);

        this.filter = {
            date_from: null,
            date_to: null
        };
    }

    componentDidMount() {
        if (this.props.dateRangeFrom) {
            this.filter.date_from = this.props.dateRangeFrom;
            this.calendarFrom.setState({value: this.props.dateRangeFrom});
            this.calendarFrom.inputElement.value = this.formatDate(this.props.dateRangeFrom);
        }

        this.validateDateRange();
    }

    formatDate(date) {
        let month = String(date.getMonth()+1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');
        return date.getFullYear() + "/" + month + "/" + day;
    }

    onFilterDate() {
        this.props.onReloadList(this.filter.date_from, this.filter.date_to);
    }

    onDateClean() {
        this.filter.date_from = null;
        this.filter.date_to = null;
        this.calendarFrom.setState({value: null});
        this.calendarTo.setState({value: null});
        this.calendarFrom.inputElement.value = "";
        this.calendarTo.inputElement.value = "";

        this.filterButton.element.setAttribute('disabled', 'disabled');
        this.dateClearButton.element.style.display = 'none';
        this.onFilterDate();
    }

    onDateCleanRaw() {
        this.filter.date_from = null;
        this.filter.date_to = null;
        this.calendarFrom.setState({value: null});
        this.calendarTo.setState({value: null});
        this.calendarFrom.inputElement.value = "";
        this.calendarTo.inputElement.value = "";

        this.filterButton.element.setAttribute('disabled', 'disabled');
        this.dateClearButton.element.style.display = 'none';
    }

    onChange() {
        if (this.props.onChange) {
            this.props.onChange(this.filter);
        }
    }
    onDateFromChange(ev) {
        this.filter.date_from = ev.value;
        this.validateDateRange();
        this.onChange();
    }

    onDateToChange(ev) {
        this.filter.date_to = ev.value;
        this.validateDateRange();
        this.onChange();
    }

    validateDateRange() {
        let filled = (this.filter.date_from || this.filter.date_to);
        this.dateClearButton.element.style.display = filled ? 'inherit' : 'none';

        if (filled) {
            this.filterButton.element.removeAttribute('disabled');
        } else {
            this.filterButton.element.setAttribute('disabled', 'disabled');
        }
    }

    render() {
        return <div className="eba-toolbar-subleft">
            <div className="p-inputgroup">
                <Calendar
                    ref={el => this.calendarFrom = el}
                    placeholder="с"
                    dateFormat="yy/mm/dd"
                    navigator={true}
                    showButtonBar={true}
                    showIcon={false}
                    onChange={(e) => this.onDateFromChange(e)}/>

                <Calendar
                    ref={el => this.calendarTo = el}
                    placeholder="по"
                    dateFormat="yy/mm/dd"
                    navigator={true}
                    showButtonBar={true}
                    showIcon={false}
                    onChange={(e) => this.onDateToChange(e)}/>

                <Button ref={el => this.dateClearButton = el} icon="pi pi-times" className="p-button-secondary" onClick={() => this.onDateClean()} />
                <Button ref={el => this.filterButton = el} icon="pi pi-chevron-circle-right" onClick={() => this.onFilterDate()} />
            </div>
        </div>
    }
}
