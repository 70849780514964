import React from 'react';
import {CustomEditPage} from "./CustomEditPage";
import {Button} from "primereact/button";
import {CourierNoteBlock} from "../Element/CourierNoteBlock";
import {OrderListByCourier} from "../EntityList/OrderListByCourier";
import {CourierDocuments} from "../Element/CourierDocuments/CourierDocuments";
import {CourierMsgSendBlock} from "../Element/CourierMsgSendBlock";
import Config from '../../service/Config';
import {TabPanel, TabView} from "primereact/tabview";
import {Map, Placemark, YMaps} from "react-yandex-maps";

export class CourierEditPage extends CustomEditPage {
/*
    constructor(props) {
        super(props);
    }
*/

    componentDidMount() {
        super.componentDidMount();
        this.setState({geoPosition: {}, courierInfoActiveTab: 0, updated: false})
    }

    getMainFormFields() {
        let fields = [
            {name: "phone", label: "Телефон", type: "string", maxLength: 11, isChanged: false},
            {name: "candy_wrapper", label: "Баллы", type: "money", isChanged: false},
            {name: "blocked", label: "Заблокирован", type: "boolean", isChanged: false},
            {name: "access_delivery_internet_shop", label: "Выручка", type: "boolean", isChanged: false},
            {name: "access_delivery_legal", label: "Безнал", type: "boolean", isChanged: false},
            {
                name: "status",
                label: "Статус",
                type: "select",
                isChanged: false,
                selectOptions: [{value:0, label: "Новичок"}, {value: 1, label: "Специалист"}, {value: 2, label: "Профессионал"}]
            },

            {name: "req", label: "Реквизиты", type: "header", isChanged: false},
            {name: "bank_name", label: "Наименование банка", type: "string", isChanged: false},
            {name: "bic", label: "БИК", type: "string", isChanged: false},
            {name: "payment_account", label: "Расчётный счёт", type: "string", isChanged: false},
            {name: "correspondent_account", label: "Корреспондентский счёт", type: "string", isChanged: false},
            {
                name: "req_verify_status",
                label: "Статус проверки реквизитов",
                type: "select",
                isChanged: false,
                selectOptions: [{value:0, label: "Новый"}, {value: 1, label: "На проверке"}, {value: 2, label: "Подтверждено"}]
            },

            {name: "profile", label: "Профиль", type: "header", isChanged: false},
            {name: "last_name", label: "Фамилия", type: "string", isChanged: false},
            {name: "first_name", label: "Имя", type: "string", isChanged: false},
            {name: "middle_name", label: "Отчество", type: "string", isChanged: false},
            {name: "email", label: "e-mail", type: "string", isChanged: false},
            {name: "inn", label: "ИНН", type: "string", isChanged: false},
            {name: "snils", label: "СНИЛС", type: "string", isChanged: false},
            {name: "address", label: "Адрес", type: "string", isChanged: false},
            {name: "region", label: "Регион", type: "string", isChanged: false},
            {name: "index", label: "Почтовый индекс", type: "string", isChanged: false},
            {name: "birthday", label: "День рождения", type: "string", isChanged: false},

            {name: "passport", label: "Паспорт", type: "header", isChanged: false},
            {name: "passport_series", label: "Серия", type: "string", isChanged: false},
            {name: "passport_number", label: "Номер", type: "string", isChanged: false},
            {name: "passport_issued_by", label: "Выдан кем", type: "string", isChanged: false},
            {name: "passport_issued_day", label: "Выдан дата", type: "string", isChanged: false},
            {name: "passport_address", label: "Адрес", type: "string", isChanged: false},

            {
                name: "verify_status",
                label: "Статус проверки профиля",
                type: "select",
                isChanged: false,
                selectOptions: [{value:0, label: "Новый"}, {value: 1, label: "На проверке"}, {value: 2, label: "Подтверждено"}]
            }
        ];

        return fields;
    }

    getReadOnlyFieldsDefs() {
        return [
            {name: "id", label: "id"},
            {name: "created_on", label: "Создано", formatter: window.globalStorage.formatter.formatDateTime},
            {name: "update_date", label: "Обновлено", formatter: window.globalStorage.formatter.formatDateTime},
            {name: "balance", label: "Баланс", formatter: window.globalStorage.formatter.formatMoneyInCents},
            {name: "completed_orders", label: "Заявок выполнено"},
            {name: "late_orders", label: "Заявок просрочено"},
            {name: "rating", label: "Рейтинг", formatter: window.globalStorage.formatter.formatMoneyInCents},
            {name: "bank_card_added", label: "Карта привязана", formatter: this.formatBool},
            {name: "self_employed_status", label: "Самозанятый", formatter: this.formatSelfEmployedStatus},
        ];
    }

    formatBool(val) {
        return val ? "да" : "нет";
    }

    formatSelfEmployedStatus(status) {
        if(status==='YES') {
            var result = 'Да';
        } else if(status==='NO' || !status) {
            result = 'Нет';
        } else {
            result = '?'
        }
        return result;
    }

    mapStatus(status) {
        const mapping = {
            0: "новичок",
            1: "специалист",
            2: "профессионал"
        };

        if (null === status || !mapping.hasOwnProperty(status)) {
            return null;
        }

        return mapping[status];
    }

    setActiveCourierInfoTab(idx){
        if(idx===1 && !this.state.updated) {
            window.globalStorage.restService.getCourierGeoPosition(this.state.entity_id)
                .then((data) => {
                    // console.log(data);
                    const date = new Date(data.coordinate_update_date);
                    data.coordinate_update_date = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
                    this.setState({geoPosition: data, updated: true, courierInfoActiveTab: idx});
                })
                .catch((err) => {
                    window.globalStorage.error(err.message);
                });
        } else {
            this.setState({courierInfoActiveTab: idx});
        }

    }

    getRightPanelContent() {
        let avatarurl = Config().host+"/v1/courier/avatar/get/"+this.state.entity_id;

        return <div className="card p-col-12 p-lg-7">
            <TabView activeIndex={this.state.courierInfoActiveTab} onTabChange={(e) => this.setActiveCourierInfoTab(e.index)}>
                <TabPanel header='Информация' leftIcon='pi pi-id-card'>
                    <div className="p-grid" style={{height: '530px'}}>
                        <div className="p-col-6"
                             style={{backgroundImage: `url('${avatarurl}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPositionX: 'center'}}>
                        </div>
                        <div className="p-col-6">
                            {this.getReadOnlyFieldsContent()}
                            <CourierNoteBlock entityId={this.state.entity_id} />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header='Карта' leftIcon="pi pi-globe">
                    {   this.state.courierInfoActiveTab===1 &&
                        <YMaps query={{apikey:'a73705c3-5b99-494a-bc39-6bb263ddcb0c'}}>
                        <Map defaultState={{ center: [55.751574, 37.573856], zoom: 9 }} height={530} width='100%'>
                            {this.state.geoPosition &&
                                <Placemark
                                    geometry={[this.state.geoPosition.latitude,this.state.geoPosition.longitude]}
                                    properties={{
                                        balloonContentBody:
                                            `<div style='display:flex'>
                                                <image src='${avatarurl}' style='width:50px'/>
                                                <div style="display: flex; flex-direction: column">
                                                    <div style="white-space: normal">${this.state.geoPosition.fio}</div>
                                                    <div style="white-space: normal">${this.state.geoPosition.coordinate_update_date}</div>
                                                </div>
                                            </div>`,
                                    }}
                                    options={{
                                    }}
                                    modules= {
                                        ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                    }
                                    instanceRef={ ref => {
                                        ref && ref.balloon.open();
                                    }}
                                />
                            }
                        </Map>
                    </YMaps>
                    }

                </TabPanel>
            </TabView>


            <CourierMsgSendBlock entityId={this.state.entity_id}/>

            <CourierDocuments courierId={this.state.entity_id} />

            {/*<div className="p-col-12">
                <h2>Debug</h2>
                <pre ref={(el) => this.json_stub = el}></pre>
            </div>*/}
        </div>;
    }

    processEntity(data) {
        this.setState({entity_name: data.last_name + ' ' + data.first_name + ' ' + data.middle_name});

        let newData = Object.assign({}, data);
        newData.candy_wrapper = window.globalStorage.formatter.formatMoneyInCents(newData.candy_wrapper);

        return newData;
    }

    beforeSavePreprocess(data) {
        if (data.hasOwnProperty('candy_wrapper')) {
            data.candy_wrapper = window.globalStorage.formatter.convertToCents(data.candy_wrapper);
        }

        return data;
    }

    onConfirmProfile() {
        window.globalStorage.restService.saveEntity(this.state.entity_type, this.state.entity_id, {registered: true})
            .then((data) => {
                let obj = this.state.entity_obj;
                obj.registered = true;

                this.setState({entity_obj: obj});
                this.showSuccess("Профиль подтверждён");
            }).catch((err) => {
                // this.showError(err.message)
                window.globalStorage.error(err.message);
            });
    }

    returnProfileToCommonList() {
        window.globalStorage.restService.resetCourierAssignee(this.state.entity_id)
            .then(this.onBack)
            .catch((err) => {
                // this.showError(err.message)
                window.globalStorage.error(err.message);
            });
    }

    getCustomButtons() {
        if (false === this.state.entity_obj.registered) {
            return (<span>
                <Button label="Вернуть в общий список" icon="pi pi-undo" iconPos="right" onClick={() => {this.returnProfileToCommonList()}} />
                &nbsp;
                <Button label="Подтвердить анкету" className="p-button-success" icon="pi pi-check" iconPos="right" onClick={() => {this.onConfirmProfile()}} />
            </span>);
        }

        return null;
    }

    getBottomContent() {
        return <div>
            <OrderListByCourier itemsCount={10} courierId={this.state.entity_id} statusFilter="bycourier" />
        </div>;
    }
}
