import React, {Component} from 'react';
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";

export class CourierNoteBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            note: ''
        };

        this.save = this.save.bind(this);
    }

    componentDidMount() {
        window.globalStorage.restService.getEntity("courier/note", this.props.entityId)
            .then((data) => {this.setState({note: data.note})})
            .catch((err) => {window.globalStorage.error(err.message)});
    }

    save() {
        let txt = this.noteTextarea.element.value;
        if (4096 < txt.length) {
            window.globalStorage.error("Слишком длинная заметка (более 4096 символов). Не будет сохранено.");
            return;
        }

        window.globalStorage.restService.saveEntity("courier/note", this.props.entityId, {note: this.state.note})
            .then((data) => {window.globalStorage.success("Заметка сохранена успешно")})
            .catch((err) => {window.globalStorage.error(err.message)});
    }


    render() {
        return <div className="p-col-12">
            <h2>Заметка о курьере</h2>
            <InputTextarea ref={el => this.noteTextarea = el} rows={5} placeholder="Текст заметки" autoResize={true} onChange={(e) => this.setState({note: e.target.value})} value={this.state.note} />
            <br/>
            <Button label="Сохранить" className="p-button-secondary" icon="pi pi-check-circle" iconPos="right" onClick={this.save} />
        </div>
    }
}
