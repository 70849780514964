import {RestService} from "./RestService";
import {FormatterService} from "./FormatterService";

export class GlobalStorage {
    constructor() {
        this.isLogged = false;
        this.stat = null;
        this.restService = new RestService();
        this.statUpdateCbList = {};
        this.idAutoUpdate = null;
        this.autoUpdateTime = 30000;
        this.formatter = new FormatterService();

        this.entitiesDictionary = {
            "delivery": {s: "Заявка", m: "Заявки"},
            "customer": {s: "Клиент", m: "Клиенты"},
            "courier": {s: "Курьер", m: "Курьеры"},
        };
    }

    getEntityNameSingle(type) {
        return this.entitiesDictionary[type].s;
    }

    getEntityNameMulti(type) {
        return this.entitiesDictionary[type].m;
    }

    setLoggedIn(isLogged, needLogOut, goRoot) {
        this.isLogged = isLogged;

        if(isLogged) {
            if(goRoot) {
                window.location = "/#";
            }
            this.startAutoUpdates();
        } else {
            this.stopAutoUpdates();
            if (needLogOut) {
                this.restService.logout()
                    .then(() => {window.location = "/#/login";});
            } else {
                window.location = "/#/login";
            }
        }
    }

    startAutoUpdates() {
        if (null === this.idAutoUpdate) {
console.log('startAutoUpdates');
            this.idAutoUpdate = setInterval(() => {this.onStatUpdate()}, this.autoUpdateTime);
        }
    }

    stopAutoUpdates() {
        if (null !== this.idAutoUpdate) {
console.log('stopAutoUpdates');
            clearInterval(this.idAutoUpdate);
            this.idAutoUpdate = null;
        }
    }

    onStatUpdate() {
// console.log('try to update stat');
        window.globalStorage.restService.status()
            .then((data) => {
// console.log('got new stat', data);
                window.globalStorage.stat = data;

                for(let n in this.statUpdateCbList) {
                    let cb = this.statUpdateCbList[n];
                    cb();
                }
            }).catch(() => {});
    }

    addOnStatUpdate(cb,id) {
console.log("Add callback " + id);
        this.statUpdateCbList[id] = cb;
    }

    removeOnStatUpdate(id) {
console.log("Remove callback " + id);
        this.statUpdateCbList[id] = null;
        delete this.statUpdateCbList[id];
    }

    isMassSendAllowed(){
        return (this.stat.user
            && this.stat.user.hasOwnProperty('rights')
            && this.stat.user.rights
            && this.stat.user.rights.hasOwnProperty('mass_send')
            && this.stat.user.rights.mass_send
        );
    }
}
