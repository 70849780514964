import React, {Component} from 'react';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

export class SearchBlock extends Component {
    componentDidMount() {
        this.searchButton.element.setAttribute('disabled', 'disabled');
        this.searchClearButton.element.style.display = 'none';
    }

    onSearch() {
        this.props.onReloadList(this.searchInput.element.value);
    }

    onSearchKeyPress(ev) {
        if (13 === ev.charCode
            && (
                !this.searchButton.element.hasAttribute('disabled')
                || 0 === ev.currentTarget.value.length
            )
        ) {
            this.onSearch();
        }
    }

    onSearchInput(ev) {
        let inputLen = ev.currentTarget.value.length;

        this.searchClearButton.element.style.display = (inputLen > 0) ? 'inherit' : 'none';

        if (inputLen < 3) {
            this.searchButton.element.setAttribute('disabled', 'disabled');
        } else {
            this.searchButton.element.removeAttribute('disabled');
        }
    }

    onSearchClean() {
        this.searchInput.element.value = '';
        this.searchButton.element.setAttribute('disabled', 'disabled');
        this.searchClearButton.element.style.display = 'none';
        this.onSearch();
    }

    render() {
        return <div className="p-inputgroup eba-toolbar-subleft">
            <InputText
                ref={el => this.searchInput = el}
                onInput={(ev) => this.onSearchInput(ev)}
                onKeyPress={(ev) => this.onSearchKeyPress(ev)} />

            <Button ref={el => this.searchClearButton = el} icon="pi pi-times" className="p-button-secondary" onClick={() => this.onSearchClean()} />
            <Button ref={el => this.searchButton = el} icon="pi pi-search" onClick={() => this.onSearch()} />
        </div>
    }
}
