import React, {Component} from 'react';
import {TabPanel, TabView} from "primereact/tabview";
import {CourierList} from "./CourierList";

export class CourierListUnapproved extends Component
{
    render() {
        return <TabView>
            <TabPanel header="Мои" leftIcon="pi pi-user-plus">
                <CourierList statusFilter="unapproved/my" statusCaption="на одобрение (мои)"/>
            </TabPanel>
            <TabPanel header="Неразобранные" leftIcon="pi pi-list">
                <CourierList statusFilter="unapproved" statusCaption="на одобрение (неразобранные)"/>
            </TabPanel>
        </TabView>
    }
}
