import axios from 'axios';
import Config from '../service/Config'

export class RestService {
    constructor() {
        this.host = Config().host;
    }

    post(url, data) {
        return axios.post(this.host + url, data)
            .then((res) => {return this.processResponse(res)});
    }

    get(url) {
        return axios.get(this.host + url)
            .then((res) => {return this.processResponse(res)});
    }

    processResponse(res) {
        if ('undefined' === typeof(res.data.status) || 'undefined' === typeof(res.data.data)) {
            throw new Error('Системная ошибка');
        }

        if ('success' !== res.data.status) {
            if ('undefined' === typeof(res.data.message)) {
                throw new Error('Системная ошибка');
            }

            if ('Unauthorized' === res.data.message) {
                window.globalStorage.setLoggedIn(false, false);
            }

            throw new Error(res.data.message);
        }

        return res.data.data;
    }

    status() {
        return this.post('/v1/status', {});
    }

    getEntityList(entityType, filter) {
        return this.post('/v1/' + entityType + '/list/' + filter.status, filter);
    }

    login(login, pass) {
        return this.post('/v1/user/login', {login: login, password: pass});
    }

    logout() {
        return this.post('/v1/user/logout', {});
    }

    saveNote(text) {
        return this.post('/v1/note/save', {note: text});
    }

    getNote() {
        return this.get('/v1/note/get');
    }

    massMsg(text, id) {
        id = id ? '/'+id : '';
        return this.post('/v1/message/send/mass' + id, {message: text});
    }

    isDeliveryExists(id) {
        return this.get('/v1/delivery/exists/' + id);
    }

    resetCourierAssignee(courierId) {
        return this.postEntityAction('courier', 'reset_assignee', courierId, {});
    }

    getEntity(entityType, id) {
        return this.get('/v1/' + entityType + '/get/' + id);
    }

    postEntityAction(entityType, action, id, data) {
        return this.post('/v1/' + entityType + '/' + action + '/' + id, data);
    }

    saveEntity(entityType, id, data) {
        return this.postEntityAction(entityType, 'save', id, data);
    }

    addEntity(entityType, data) {
        return this.post('/v1/' + entityType + '/add', data);
    }

    deleteEntity(entityType, id) {
        return axios.delete(this.host + '/v1/' + entityType + '/delete/' + id)
            .then((res) => {return this.processResponse(res)});
    }

    getDocumentsMeta(id) {
        return this.get('/v1/courier/document/list/' + id);
    }

    getCourierGeoPosition(idCourier) {
        return this.get('/v1/courier/geoposition/get/'+idCourier);
    }

    getCourierGeoPositionList(list) {
        return this.post('/v1/courier/geoposition/list',list);
    }

    getCourierGeoPositionListAll() {
        return this.get('/v1/courier/geoposition/list-all');
    }

    removeCourierFromDelivery(id) {
        return this.get('/v1/courier/remove-from-delivery/'+id);
    }

    removeCourierFromDeliveryWithPenalty(id) {
        return this.get('/v1/courier/remove-from-delivery-with-penalty/'+id);
    }

    getDeliveryTariff() {
        return this.get('/v1/delivery-tariff/get/1');
    }

    setDeliveryTariff(data) {
        return this.post('/v1/delivery-tariff/save/1',data);
    }

    isDeliveryReceiptExists(id) {
        return this.get('/v1/delivery/isreceiptexists/'+id);
    }

    /**
     *
     * @param {int} id Delivery ID
     * @returns {Promise<*>}
     */
    getFnsDeliveryReregistration(id) {
        return this.get('/v1/delivery/getfnsregistration/'+id);
    }

}
