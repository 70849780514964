import React, {Component} from 'react';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

export class Coeff extends Component {
    constructor(props){
        super(props);

        this.state = {
            zone_price_ratio: 0
        };

    }

    componentDidMount() {
        window.globalStorage.restService.getDeliveryTariff().then(data=>{
            this.setState(data);
        })
    }

    onSave() {
        const data = {
            zone_price_ratio : this.state.zone_price_ratio
        };
        window.globalStorage.restService.setDeliveryTariff(data).then(()=>{
            window.globalStorage.success('Записано успешно');
        }).catch(()=>{
            window.globalStorage.error('Ошибка записи');
        });
    }

    render() {
        return <div className="p-inputgroup eba-toolbar-subleft">
            <InputText
                value={this.state.zone_price_ratio}
                onChange={(e) => this.setState({zone_price_ratio: e.target.value})}
            />

            <Button label="Сохранить" icon="pi pi-check-circle" iconPos="right" onClick={() => this.onSave()} />
        </div>
    }
}
