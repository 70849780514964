import React, {Component} from 'react';
import {TabPanel, TabView} from "primereact/tabview";
import { YMaps, Map, Placemark } from 'react-yandex-maps';

export class CourierGeoPosition extends Component {

    constructor(props){
        super(props);

        this.state = {
            geoPositions: [],
            pos: [],
            updated: false
        }
    }

    componentDidMount() {

        if(!this.state.updated) {
            window.globalStorage.restService.getCourierGeoPositionListAll()
                .then((data) => {
                    this.setState({geoPositions: data, updated: true})
                })
                .catch((err) => {
                    window.globalStorage.error(err.message);
                });
        }
    }

    render() {
        return(
            <div>
                <TabView>
                    <TabPanel ref='tp' header="Карта курьеров" leftIcon="pi pi-globe">
                        <YMaps query={{apikey:'a73705c3-5b99-494a-bc39-6bb263ddcb0c'}}>
                            <Map defaultState={{ center: [55.751574, 37.573856], zoom: 9 }} width={800} height={600}>
                                {this.state.geoPositions.map((item, index)=>
                                    <Placemark
                                        key={'placemark_'+index}
                                        geometry={[item.latitude,item.longitude]}
                                        properties={{
                                            // iconContent:item.fio.replace(/\s+/g,'<br>'),
                                            iconContent:item.fio,
                                            // hasHint: true,
                                        }}
                                        options={{
                                            preset:'islands#blueStretchyIcon',
                                        }}
                                        // onClick={()=>{window.alert('JOPA')}}
                                    />)
                                }
                            </Map>
                        </YMaps>
                    </TabPanel>
                </TabView>
            </div>
        )
    }
}
