import React, {Component} from 'react';
import {SelectButton} from "primereact/selectbutton";
import {ReadOnlyFieldList} from "../ReadOnlyFieldList";
import Config from '../../../service/Config';

export class DocumentItem extends Component {
    constructor(props) {
        super(props);

        this.selectOptions = [{value:0, label: "Новый"}, {value: 1, label: "На проверке"}, {value: 2, label: "Подтверждено"}];
    }

    render() {
        const fields = [
            {name: "upload_on", label: "Загружено", formatter: window.globalStorage.formatter.formatDateTime}
        ];

        if (this.props.data.exists) {
            var imgUrl = Config().host+"/v1/courier/document/get/" + this.props.courierId + "/" + this.props.code + "/thumb";
            var fileUrl = Config().host+"/v1/courier/document/get/" + this.props.courierId + "/" + this.props.code + "/full";
        }

        let className = this.props.isChanged ? "eba-toggle-input-changed" : "";

        return (imgUrl || fileUrl) ? <div className={'eba-courier-doc-thumb ' + this.props.className}>
            <div
                className='docs-item__image'
                onClick={()=>fileUrl && this.props.onEnlarge(fileUrl)}
                style={{backgroundImage:`url(${imgUrl})`}}
            >
            </div>
            <div className="docs-item__info">
                {this.props.data.upload_on && <ReadOnlyFieldList fields={fields} entityObj={this.props.data} caption={this.props.label} />}
                <SelectButton
                    options={this.selectOptions}
                    value={this.props.data.verify_status}
                    onChange={this.props.onChange}
                    name={this.props.code}
                    className={className} />
            </div>
        </div> : '';
    }
}
