export class FormatterService {
    formatMoneyInCents(cval) {
        let sval = '';

        if (Math.abs(cval) >= 100) {
            sval = String(cval);
            let sl = sval.length;
            sval = sval.substring(0, sl-2) + "." + sval.substring(sl-2);
        } else {
            sval = String(Math.abs(cval));
            sval = "0." + sval.padStart(2, '0');

            if (cval < 0) {
                sval = "-" + sval;
            }
        }

        return (sval);
    }

    convertToCents(cval) {
        let pieces = cval.split(/[,.]/);

        let val;
        if (pieces.length > 1) {
            let cents = pieces[1].trim();
            if (cents.length > 2) {
                cents = cents.substring(0, 2);
            }
            val = pieces[0].trim() + cents.padEnd(2, "0");
        } else {
            val = pieces[0].trim() + "00";
        }

        val = Number(val);
        return (isNaN(val) || null === val) ? 0 : val;
    }

    formatDateTime(date) {
        if (!date) {
            return null;
        } else if (typeof(date)==='string') {
            date = new Date(date);
        }

        let month = String(date.getMonth()+1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');
        let hh = String(date.getHours()).padStart(2, '0');
        let mm = String(date.getMinutes()).padStart(2, '0');

        return date.getFullYear() + "-" + month + "-" + day + " " + hh + ":" + mm;
    }

    cleanupMoneyString(ms) {
        return ms.replace(/[^0-9,.]/, "");
    }

    mapCustomerType(type) {
        const typeMapping = {
            0: "физ",
            1: "юр"
        };

        if (null === type || !typeMapping.hasOwnProperty(type)) {
            return null;
        }

        return typeMapping[type];
    }

    TransportType(intType) {
        return ['Пешком','Автомобилем','Грузовик'][intType];
    }

}
