import React, {Component} from 'react';
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";

export class NotesBlock extends Component {
    componentDidMount() {
        window.globalStorage.restService.getNote()
            .then((data) => {this.noteTextarea.element.value = data.note})
            .catch((err) => {window.globalStorage.error(err)});
    }

    send() {
        let txt = this.noteTextarea.element.value;
        if (4096 < txt.length) {
            window.globalStorage.error("Слишком длинная заметка (более 4096 символов). Не будет сохранено.");
            return;
        }

        window.globalStorage.restService.saveNote(txt)
            .then(() => {
                window.globalStorage.success("Заметка сохранена успешно");
            }).catch((err) => {
                window.globalStorage.error(err)
            });
    }

    render() {
        return <div>
            <h1>Мои заметки</h1>
            <InputTextarea ref={el => this.noteTextarea = el} rows={4} cols={52} placeholder="Текст заметки" autoResize={true} />
            <br/>
            <Button label="Сохранить" icon="pi pi-check-circle" iconPos="right" onClick={() => {this.send()}} />
        </div>
    }
}
