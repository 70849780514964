import React, {Component} from 'react';

export default class ImageView extends Component {
    render() {
        return this.props.url
            ? <div className='image-view' style={{backgroundImage:`url(${this.props.url})`}}>
                <i className="layout-menuitem-icon pi pi-fw pi-times-circle image-view__close" onClick={()=>this.props.onClose()}/>
            </div>
            : '';
    }
}
