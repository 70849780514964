import React, {Component} from 'react';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";

export class PopupDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            reason: ""
        };

        this.onConfirm = this.onConfirm.bind(this);
    }

    onConfirm() {
        this.setState({display:false});
        this.props.onConfirm(this.state.reason);
    }

    onReject() {
        this.setState({display:false});
        this.props.onReject();
    }

    getFooter() {
        return <div>
            <Button icon="pi pi-check" onClick={()=>this.onConfirm()} label="Да" disabled={this.props.allowFeedback && (0 === this.state.reason.length)} />
            <Button icon="pi pi-times" onClick={()=>this.onReject()} label="Нет" className="p-button-secondary" />
        </div>;
    }

    render() {
        const feedback = this.props.allowFeedback ?
            <div className="p-fluid">
                <InputTextarea value={this.state.reason} onChange={(e) => {this.setState({reason: e.target.value})}} autoResize={true} rows={4} cols={52} />
            </div> :
            '';

        return <Dialog
            header={this.props.caption}
            visible={this.state.display}
            modal={true}
            style={{width: '37vw'}}
            footer={this.getFooter()}
            // onHide={() => this.onReject()}
            onHide={() => this.setState({display:false})}
        >
            <p style={{lineHeight:1.5}}>{this.props.description}</p>
            { feedback }
        </Dialog>;
    }
}
