import React from 'react';
import {CustomEditPage} from "./CustomEditPage";
import {StatusLabel} from "../Element/StatusLabel";
import {DeliveryAddressList} from "../Element/DeliveryAddress/DeliveryAddressList";
import {Button} from "primereact/button";
import {PopupDialog} from "../Element/PopupDialog";
import {CustomerShortInfoBlock} from "../Element/CustomerShortInfoBlock";
import {CourierShortInfoBlock} from "../Element/CourierShortInfoBlock";
import {FormBlock} from "../Element/FormBlock/FormBlock";
import ImageView from "../../service/ImageView";
import Config from "../../service/Config";

const DELIVERY_STATUS = {
    AVAILABLE: 0,
    ACTIVE: 1,
    COMPLETED: 2,
    CANCELLED: 3
}

export class DeliveryEditPage extends CustomEditPage {
    constructor(props) {
        super(props);
        this.template = 'singleRow';

        this.cancelDelivery = this.cancelDelivery.bind(this);
        this.cancelDeliveryDialog = this.cancelDeliveryDialog.bind(this);
        this.cancelCourier = this.cancelCourier.bind(this);
        this.cancelCourierDialog = this.cancelCourierDialog.bind(this);
        this.cancelCourierPenalty = this.cancelCourierPenalty.bind(this);
        this.cancelCourierPenaltyDialog = this.cancelCourierPenaltyDialog.bind(this);
    }

    getMainFormFields() {
        let fields = [
            {name: "cargo_info", label: "Груз", type: "string", isChanged: false},
            {name: "weight", label: "Вес", type: "number", isChanged: false},
            {name: "candy_wrapper_bonus", label: "Баллы", type: "money", isChanged: false},
            {name: "sum_customer_payment", label: 'Цена для клиента', type: 'money', isChanged: false},
            {name: "pogruzka_razgruzka", label: "Погрузка / разгрузка", type: "boolean", isChanged: false}
        ];

        return fields;
    }

    getReadOnlyFieldsDefs() {
        return [
            {name: "id", label: "id"},
            {name: "status", label: "Статус", formatter: (v) => {return <StatusLabel status={v} />}},
            {name: "created_on", label: "Создано", formatter: window.globalStorage.formatter.formatDateTime},
            {name: "completed_date", label: "Завершено"},
            {name: "cancel_date", label: "Отменено"},
            {name: "rating", label: "Рейтинг"},
            {name: "sum_customer_payment_old", label: "Цена для клиента", formatter: window.globalStorage.formatter.formatMoneyInCents},
            {name: "declared_price", label: "Объявленная ценность", formatter: window.globalStorage.formatter.formatMoneyInCents},
            {name: "transport_type", label: "Вид доставки", formatter: window.globalStorage.formatter.TransportType},
            {name: "sum_courier_income", label: "Заработок курьера", formatter: window.globalStorage.formatter.formatMoneyInCents},
        ];
    }

    onConfirm(data, success, reject){
        this.confirmSuccess = success;
        this.confirmReject = reject;
        this.setState({confirmData: data});
        this.confirmDialog.setState({display:true});
    }

    onConfirmSuccess(){
        this.confirmSuccess(this.state.confirmData);
        this.setState({confirmData: null});
    }

    onConfirmReject(){
        this.confirmReject(this.state.confirmData);
        this.setState({confirmData: null});
    }

    getRightPanelContent() {
        return(
            <div className="p-grid p-fluid p-col-12">
                <div className="card p-col-12 p-lg-4 p-md-6">
                    {this.getReadOnlyFieldsContent()}
                </div>

                <div className="card p-col-12 p-lg-4 p-md-6">
                    {this.getCustomerShortInfo()}
                </div>

                <div className="card p-col-12 p-lg-4 p-md-6">
                    {this.getCourierShortInfo()}
                </div>

                {/*<div className="p-col-12" style={{display: 'none'}}>*/}
                {/*    <h2>Debug</h2>*/}
                {/*    <pre ref={(el) => this.json_stub = el}></pre>*/}
                {/*</div>*/}
            </div>
        );
    }

    renderSingleRow(){
        return (<>
            <PopupDialog
                ref={el => this.confirmDialog = el}
                caption="Подтвердите запись"
                description={<>
                    <span>Вы точно хотите сохранить данные?</span>
                    {
                        this.state.fieldDefs.find(item=>item.name==='sum_customer_payment').isChanged
                            ? <><br/><strong style={{color:"red"}}>Внимание!!!</strong> Цена для клиента изменена!!!</>
                            :''
                    }
                    </>
                }
                onConfirm={()=>this.onConfirmSuccess()}
                onReject={()=>this.onConfirmReject()}
                // allowFeedback={false}
            />
            <div className="card card-w-title">
                <h1><strong>{this.state.caption}</strong> {this.state.entity_name} ({this.state.entity_id})</h1>

                <div className="eba-card-toolbar p-col-12">
                    <div className="eba-toolbar-subleft">
                        <Button label="Назад к списку" icon="pi pi-angle-left" iconPos="left" onClick={this.onBack} />
                        &nbsp;
                        <Button label="Сохранить" icon="pi pi-check-circle" iconPos="right" className="p-button-success" onClick={this.onSave} disabled={!this.state.canSave} />
                    </div>
                    <div className="eba-toolbar-subright">
                        {this.getCustomButtons()}
                    </div>
                </div>

                <div className="p-grid p-fluid p-col-12">
                    <div className="card p-col-12 p-lg-3 p-md-6">
                        <FormBlock entityObject={this.state.entity_obj} fieldDefs={this.state.fieldDefs} onFieldChange={this.onMainFieldChange} />

                        <hr/>

                        <Button label="Сбросить изменения" icon="pi pi-undo" iconPos="right" className="p-button-warning" onClick={() => this.onClearMainFormChanges()} disabled={!this.state.canSave} />
                    </div>

                    <div className="card p-col-12 p-lg-3 p-md-6">
                        {this.getReadOnlyFieldsContent()}
                    </div>

                    <div className="card p-col-12 p-lg-3 p-md-6">
                        {this.getCustomerShortInfo()}
                    </div>

                    <div className="card p-col-12 p-lg-3 p-md-6">
                        {this.getCourierShortInfo()}
                    </div>

                </div>
            </div>

            {this.getBottomContent()}
            <br/>
        </>
        );
    }

    processEntity(data) {

        this.setState({entity_name: data.cargo_info});

        let newData = Object.assign(data, {sum_customer_payment_old: data.sum_customer_payment, sum_customer_payment: data.sum_customer_payment/100 });
        newData.candy_wrapper_bonus = window.globalStorage.formatter.formatMoneyInCents(newData.candy_wrapper_bonus);

        // console.log('id',this.state.entity_id);
        window.globalStorage.restService.isDeliveryReceiptExists(this.state.entity_id)
            .then((data) => {
                this.setState({receiptExists: data.result})
            });

        window.globalStorage.restService.getFnsDeliveryReregistration(this.state.entity_id)
            .then((data) => {
                this.setState({fnsRegistration: data.result})
            });

        return newData;
    }

    beforeSavePreprocess(data) {
        if (data.hasOwnProperty('candy_wrapper_bonus')) {
            data.candy_wrapper_bonus = window.globalStorage.formatter.convertToCents(data.candy_wrapper_bonus);
        }
        if (data.hasOwnProperty('sum_customer_payment')) {
            data.sum_customer_payment = data.sum_customer_payment*100;
        }
        return data;
    }

    getCustomerShortInfo() {
        if (!this.state.entity_obj || !this.state.entity_obj.hasOwnProperty('client') || !this.state.entity_obj.client) {
            return null;
        }

        return <CustomerShortInfoBlock customerId={this.state.entity_obj.client}/>;
    }

    getCourierShortInfo() {
        if (!this.state.entity_obj || !this.state.entity_obj.hasOwnProperty('courier') || !this.state.entity_obj.courier) {
            return null;
        }

        return <CourierShortInfoBlock
            actions = {this.getCancelCourier()}
            deliveryId = {this.state.entity_id}
            entityId = {this.state.entity_obj.courier}
            delivery = {this.state.entity_obj}
        />;
    }

    isFormValid(){
        return this.state.fieldDefs.filter(item=>item.isInvalid).length === 0;
    }

    onMainFieldChange(fld, val) {
        let obj = this.state.entity_obj;
        obj[fld] = val;
        if(fld==='sum_customer_payment') {
            const f = this.state.fieldDefs;
            if((obj[fld]*100) < obj[fld+'_old']) {
                f.find(item=>item.name===fld).isInvalid = true;
            } else {
                f.find(item=>item.name===fld).isInvalid = false;

                const delta = obj.sum_customer_payment*100 - obj.sum_customer_payment_old;

                if(this.state.entity_obj.client.type === 0) {
                    const sum = delta*100/123; // распределить
                    const c_dirty = sum*80/100; // курьеру грязными
                    const c_clear = c_dirty*.87;
                    const c_ndfl = c_dirty-c_clear;

                    obj.sum_courier_income = Math.round((obj.sum_courier_income + c_clear)/100)*100;
                    obj.sum_courier_income_ndfl = Math.round((obj.sum_courier_income_ndfl + c_ndfl)/100)*100;
                } else {
                    obj.sum_courier_income += Math.round(delta*.8/100)*100;
                }
                obj.sum_customer_payment_old = obj.sum_customer_payment*100;
            }
            this.setState({fieldDefs: f});
        }
        this.setState({entity_obj: obj, canSave: this.isMainFormChanged(obj)});
    }

    cancelCourier(reason){
        // window.alert('removeCourierFromDelivery');
        window.globalStorage.restService.removeCourierFromDelivery(this.state.entity_obj.id)
            .then(() => {
                window.globalStorage.success("Курьер снят успешно");
                this.state.entity_obj.courier = null;
                this.setState({entity_obj: this.state.entity_obj});
            }).catch((err) => {window.globalStorage.error(err.message)});
    }

    cancelCourierPenalty(reason){
        // window.alert('removeCourierFromDeliveryWithPenalty');
        window.globalStorage.restService.removeCourierFromDeliveryWithPenalty(this.state.entity_obj.id)
            .then(() => {
                window.globalStorage.success("Курьер снят успешно");
                this.state.entity_obj.courier = null;
                this.setState({entity_obj: this.state.entity_obj});
            }).catch((err) => {window.globalStorage.error(err.message)});
    }

    cancelCourierDialog(){
        this.popupCancelCourier.setState({display:true})
    }

    cancelCourierPenaltyDialog(){
        this.popupCancelCourierPenalty.setState({display:true})
    }

    getCancelCourier(){
        if (!this.state.entity_obj.courier) {
            return '';
        }
        return <span>
            <Button
                label="Снять"
                tooltip='Снять с заказа без штрафа'
                icon="pi pi-times-circle"
                className="p-button-danger"
                iconPos="right"
                onClick={this.cancelCourierDialog}
            /> &nbsp;
            <PopupDialog
                ref={el => this.popupCancelCourier = el}
                caption="Снять курьера с заказа"
                description="Если вы хотите снять курьера с заказа нажмите кнопку 'Да'. После этого курьер будет снят с заказа без дополнительного штрафа"
                onConfirm={this.cancelCourier}
                allowFeedback={false}
            />

            <Button
                label="Снять $"
                tooltip='Снять с заказа со штрафом'
                icon="pi pi-times-circle"
                className="p-button-danger"
                iconPos="right"
                onClick={this.cancelCourierPenaltyDialog}
            />
            <PopupDialog
                ref={el => this.popupCancelCourierPenalty = el}
                caption="Снять курьера с заказа со штрафом"
                description="Если вы хотите снять курьера с заказа нажмите кнопку 'Да'. После этого курьер будет снят с заказа со штрафом"
                onConfirm={this.cancelCourierPenalty}
                allowFeedback={false}
            />

        </span>;
    }

    getBottomContent() {
        if (!this.state.entity_id) {
            return null;
        }

        return <DeliveryAddressList deliveryId={this.state.entity_id}/>
    }

    viewReceiptPhoto(){
        const img = this.state.receiptPhotoUrl ? '' : Config().host+'/v1/delivery/getreceipt/'+this.state.entity_id;
        this.setState({receiptPhotoUrl: img})
    }

    viewReceipt(){
        const img = this.state.receiptUrl ? '' : this.state.fnsRegistration;
        this.setState({receiptUrl: img})
    }

    getCustomButtons() {
        // if (!this.state.entity_obj || !this.state.entity_obj.hasOwnProperty('status') || 3 === this.state.entity_obj.status || 2 === this.state.entity_obj.status) {
        //     return null;
        // }

        if(this.state.entity_obj && this.state.entity_obj.hasOwnProperty('status')) {
            if([DELIVERY_STATUS.ACTIVE, DELIVERY_STATUS.AVAILABLE].indexOf(this.state.entity_obj.status) >= 0) {
                return <span>
                    <Button label="Отменить заявку" icon="pi pi-times-circle" className="p-button-danger" iconPos="right" onClick={this.cancelDeliveryDialog} />

                    <PopupDialog
                        ref={el => this.popupCancelDelivery = el}
                        description="Если вы хотите отменить заявку, пожалуйста, напишите причину в поле для ввода ниже, и нажмите кнопку 'Да'. После этого заявка будет отменена."
                        caption="Отмена заявки"
                        onConfirm={this.cancelDelivery}
                        allowFeedback={true}
                    />
                </span>;
            } else if (this.state.entity_obj.status === DELIVERY_STATUS.COMPLETED){
                return <span>
                    {this.state.fnsRegistration && <span>
                        <Button label="Просмотр чека" icon={'pi '+ (this.state.receiptUrl ? "pi-angle-up" : "pi-angle-down")} className="p-button-text" iconPos="right" onClick={()=>this.viewReceipt()} />
                        <ImageView url={this.state.receiptUrl} onClose={()=>this.setState({receiptUrl:''})}/>
                    </span>}
                    {this.state.receiptExists && <span>
                        <Button label="Просмотр чека (фото)" icon={'pi '+ (this.state.receiptPhotoUrl ? "pi-angle-up" : "pi-angle-down")} className="p-button-text" iconPos="right" onClick={()=>this.viewReceiptPhoto()} />
                        <ImageView url={this.state.receiptPhotoUrl} onClose={()=>this.setState({receiptPhotoUrl:''})}/>
                    </span>}
                </span>;
            }
        } else {
            return '';
        }

    }

    cancelDeliveryDialog() {
        this.popupCancelDelivery.setState({display: true});
    }

    cancelDelivery(reason) {
        window.globalStorage.restService.postEntityAction(this.state.entity_type, "cancel", this.state.entity_id, {cancel_reason: reason})
            .then(() => {
                window.globalStorage.success("Заявка отменена успешно");
                let data = this.state.entity_obj;
                data.status = 3;
                this.setState({entity_obj: data});
            }).catch((err) => {window.globalStorage.error(err.message)});
    }
}
