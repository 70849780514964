import React from 'react';
import {OrderList} from "./OrderList";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {YesNo} from "../Element/FormBlock/YesNo";

export class OrderListFinished extends OrderList {
    // onBack() {
    //     window.location = "/#/" + this.state.entity_type + "/list/finished";
    // }
    //
    getTable() {
        return <DataTable
            ref={el => this.tbl = el}
            value={this.state.courierList}
            paginator={true}
            rows={this.state.filter.limit}
            first={this.state.filter.offset}
            totalRecords={this.state.countItems}
            alwaysShowPaginator={false}
            lazy={true}
            onPage={(ev) => this.onPage(ev)}
            onRowDoubleClick={(ev) => {this.onRowDblClick(ev)}}
            emptyMessage="Список пуст">

            <Column field="id" header="id" className="eba-id-column"/>
            <Column field="cargo_info" header="груз"/>
            <Column field="declared_price" body={this.formatMoneyInCents} header="объявленная ценность" className="eba-numeric-column" />
            <Column field="sum_customer_payment" body={this.formatMoneyInCents} header="цена для клиента" className="eba-numeric-column" />
            <Column field="candy_wrapper_bonus" header="баллы" className="eba-numeric-column" style={{width: '80px'}} body={this.formatMoneyInCents} />
            <Column field="mark" header="оценка" className="eba-numeric-column" style={{width: '80px'}} />
            <Column field="courier_fio" header="курьер"/>
            <Column field="sum_courier_income" header="заработок курьера" body={this.formatMoneyInCents} className="eba-numeric-column" />
            <Column field="created_on" header="создан" style={{width: '122px'}} />
            <Column field="completed_date" header="выполнен" style={{width: '122px'}} />
            <Column field="rec_id" header="чек" style={{width: '30px'}} body={(r,f) => <YesNo state={r[f.field]}/>}/>

        </DataTable>
    }
}
