import React, {Component} from 'react';
import {ReadOnlyFieldList} from "./ReadOnlyFieldList";

export class CourierShortInfoBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: [
                {name: "id", label: "id"},
                {name: "phone", label: "Телефон"},
                {name: "last_name", label: "Фамилия"},
                {name: "first_name", label: "Имя"},
                {name: "middle_name", label: "Отчество"},
                {name: "sum_courier_income", label: "Заработок", formatter: window.globalStorage.formatter.formatMoneyInCents},
            ],
            info: {}
        };
    }

    componentDidMount() {
        window.globalStorage.restService.getEntity("courier", this.props.entityId)
            .then((data) => {
                data.sum_courier_income = this.props.delivery.sum_courier_income;
                this.setState({info: data});
            })
            .catch((err) => {
                window.globalStorage.error(err.message)
                // console.log(err);
            });
    }

    // processEntity(data) {
    //     this.setState({entity_name: data.login});
    //     return data;
    // }
    //
    render() {
        let footer = <a href={"/#/courier/edit/" + this.props.entityId}>Смотреть профиль курьера</a>;
        return (
            <ReadOnlyFieldList
                actions = {this.props.actions}
                caption = "Курьер"
                fields = {this.state.fields}
                entityObj = {this.state.info}
                footer = {footer}
            />
        );
    }
}
