import React, {Component} from 'react';
import {FormBlock} from "../FormBlock/FormBlock";
import {Button} from "primereact/button";
import misc from '../../../service/Misc'

export class DeliveryAddressEditPanel extends Component {
    constructor(props) {
        super(props);

        // const data = props.entityObject;
        // data.arrival_wait_from_to = `${data.arrival_date} - ${data.arrival_wait_to}`;

        this.state = {
            fieldDefs: [
                {name: "contact_phone", label: "Контактный телефон", type: "string", isChanged: false},
                {name: "contact_name", label: "Имя контакта", type: "string", isChanged: false},
                {name: "address", label: "Адрес", type: "address", isChanged: false},
                {name: "comment", label: "Комментарий", type: "string", isChanged: false},
                {name: "arrival_date", label: "Ожидаемое время прибытия с", type: "datetime", isChanged: false},
                {name: "arrival_wait_time", label: "Время ожидания на месте", type: "number", isChanged: false},
                // {name: "arrival_wait_to", label: "Ожидаемое время прибытия до", type: "string", isChanged: false, readOnly: true },
                {name: "arrival_wait_to", label: "Ожидаемое время прибытия до", type: "datetime", isChanged: false, skipUpdate: true },
                // {name: "arrival_wait_from_to", label: "Ожидаемое время прибытия с..до", type: "string", isChanged: false, readOnly: true, style: {width: '276px'} },
                {name: "internet_shop_id", label: "Внутренний номер заказа", type: "string", isChanged: false},
                {name: "internet_shop_price", label: "Получить и перевести наличные", type: "number", isChanged: false},
                {name: "payment_here", label: "Оплата на месте", type: "boolean", isChanged: false}
            ],
            entityObject: Object.assign({}, props.entityObject),
            entityObjectOriginal: Object.assign({}, props.entityObject),
            canSave: false,
            isFormValid: true
        };

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onClearMainFormChanges = this.onClearMainFormChanges.bind(this);
    }

    isFormChanged(entity_obj) {
        let isChanged = false;
        let fieldDefs = this.state.fieldDefs;

        for (let n in fieldDefs) {
            if (fieldDefs.hasOwnProperty(n)) {
                if ('header' === fieldDefs[n].type) {
                    continue;
                }

                let fldName = fieldDefs[n].name;
                if (this.state.entityObjectOriginal[fldName] !== entity_obj[fldName]) {
                    isChanged =  true;
                    fieldDefs[n].isChanged = true;
                } else {
                    fieldDefs[n].isChanged = false;
                }
            }
        }

        this.setState({fieldDefs: fieldDefs});

        return isChanged;
    }

    invalidateWaitTime(obj){
        let fieldDefs = this.state.fieldDefs;
        let isInvalid = fieldDefs[this.state.fieldDefs.findIndex(item => item.name === 'arrival_wait_time')].isInvalid =
            !(0 < obj['arrival_wait_time'] && obj['arrival_wait_time'] < 86400000);
        this.setState({fieldDefs: fieldDefs, isFormValid: !isInvalid});
    }

    onFieldChange(fld, val) {
        let obj = this.state.entityObject;
        obj[fld] = val;

        if(fld==='arrival_wait_time') {
            obj['arrival_wait_to'] = misc.getArrivalWaitTo(obj['arrival_date'],obj['arrival_wait_time']);
            this.invalidateWaitTime(obj);
        } else if(fld==='arrival_wait_to') {
            obj['arrival_wait_time'] = misc.getArrivalWaitTime(obj['arrival_wait_to'],obj['arrival_date']);
            this.invalidateWaitTime(obj);
        }

        this.setState({entityObject: obj, canSave: this.isFormChanged(obj)});
    }

    resetIsChangedFlags() {
        let fieldDefs = this.state.fieldDefs;
        for (let n in fieldDefs) {
            fieldDefs[n].isChanged = false;
        }

        this.setState({canSave: false, fieldDefs: fieldDefs});
    }

    onClearMainFormChanges() {
        this.resetIsChangedFlags();
        this.setState({entityObject: Object.assign({}, this.state.entityObjectOriginal)});
    }

    onSave() {
        let data = {};

        for (let n in this.state.fieldDefs) {
            if (this.state.fieldDefs.hasOwnProperty(n)) {
                if ('header' === this.state.fieldDefs[n].type || this.state.fieldDefs[n].skipUpdate) {
                    continue;
                }

                let field = this.state.fieldDefs[n];
                if (field.isChanged) {
                    data[field.name] = this.state.entityObject[field.name];
                }
            }
        }

        this.props.onSave(data);
    }

    render() {
        if (!this.props.visible) {
            return null;
        }

        return <div className="p-col-12 eba-address-list-editor p-grid">
            <h2 className="p-col-12">Редактировать адрес доставки</h2>
            <div className="p-col-12 p-lg-9">
                <FormBlock entityObject={this.state.entityObject} fieldDefs={this.state.fieldDefs} onFieldChange={this.onFieldChange} twoColumnsMode={true} />
            </div>
            <div className="p-col-12 p-lg-3">
                <Button label="Сохранить" icon="pi pi-check-circle" iconPos="right" className="p-button-success"
                        disabled={!this.state.canSave || !this.state.isFormValid} onClick={this.onSave} />
                <br/><br/>
                <Button label="Сбросить изменения" icon="pi pi-undo" iconPos="right" className="p-button-warning"
                        onClick={this.onClearMainFormChanges} disabled={!this.state.canSave} />
                <br/><br/>
                <Button label="Отмена" icon="pi pi-times" iconPos="right" onClick={this.props.onCancel} />
            </div>
        </div>;
    }
}
