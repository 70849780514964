import moment from 'moment';

class Misc {
    getArrivalWaitTo (arrival_date, arrival_wait_time) {
        return moment(arrival_date).add(arrival_wait_time,'milliseconds').toISOString();
    }

    getArrivalWaitTime (arrival_wait_to, arrival_date) {
        return moment(arrival_wait_to).diff(arrival_date,'millisecond');
    }
}

export default new Misc();
