import React, {Component} from 'react';
import PropTypes from 'prop-types';

export class AppTopbar extends Component {

	static defaultProps = {
		onMenuButtonClick: null
	};

	static propTypes = {
		onMenuButtonClick: PropTypes.func.isRequired
	};

	constructor() {
		super();
		this.state = {};
	}

	render() {
		return <div className="layout-topbar clearfix">

			<button className="layout-topbar-logo p-link">
				<img id="layout-topbar-logo" alt="babylon-layout" src="assets/layout/images/easybox-logo-white.jpg"/>
			</button>

			<button className="layout-menu-button p-link" onClick={this.props.onMenuButtonClick}>
				<i className="pi pi-bars"/>
			</button>

			<button className="layout-menu-button p-link" style={{float: "right"}} onClick={() => {window.globalStorage.setLoggedIn(false, true)}}>
				<i className="pi pi-sign-out"/>
			</button>
		</div>;
	}
}