import React, {Component} from 'react';
import classNames from 'classnames';
import {AppTopbar} from './AppTopbar';
import {AppFooter} from './AppFooter';
import {AppMenu} from './AppMenu';
import {HashRouter, Route} from 'react-router-dom';
import {Dashboard2} from "./components/Dashboard2";
import {OrderListUnassigned} from "./components/EntityList/OrderListUnassigned";
import {OrderListProgress} from "./components/EntityList/OrderListProgress";
import {OrderListCancelled} from "./components/EntityList/OrderListCancelled";
import {OrderListFinished} from "./components/EntityList/OrderListFinished";
import {OrderListStuck} from "./components/EntityList/OrderListStuck";
import {OrderListExpired} from "./components/EntityList/OrderListExpired";
import {OrderList} from "./components/EntityList/OrderList";
import {CourierList} from './components/EntityList/CourierList';
import {CourierListNew} from './components/EntityList/CourierListNew';
import {CourierListUnapproved} from "./components/EntityList/CourierListUnapproved";
import {CourierGeoPosition} from "./components/EntityList/CourierGeoPosition";
import {CustomerList} from './components/EntityList/CustomerList';
import {DeliveryEditPage} from "./components/EditPage/DeliveryEditPage";
import {CourierEditPage} from "./components/EditPage/CourierEditPage";
import {CustomerEditPage} from "./components/EditPage/CustomerEditPage";

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';
import '../src/css/theme/deeporange/theme-dark.scss';
import '../src/css/layout-deeporange.scss';
import '../src/css/App.scss';

import {Growl} from "primereact/growl";

class App extends Component {

	constructor() {
		super();
		this.state = {
			layoutMode: 'static',
			overlayMenuActive: false,
			staticMenuDesktopInactive: false,
			staticMenuMobileActive: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
			darkMenu: true,
			menuActive: false,
			profileMode: 'popup',
			themeColor: 'deeporange-dark',
			grouped: true,
			inlineProfileActive: false,
			configDialogActive: false
		};

		this.onDocumentClick = this.onDocumentClick.bind(this);
		this.onMenuClick = this.onMenuClick.bind(this);
		this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
		this.onMenuItemClick = this.onMenuItemClick.bind(this);
		this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);

		this.statUpdateSubscriptionId = 'app';
		this.stat = window.globalStorage.stat;

		this.createMenu();

		window.globalStorage.addOnStatUpdate(() => {
			this.stat = window.globalStorage.stat;
			this.menuGrouped = [];
			this.createMenu();
			this.appMenuComponent.setState({});
			this.appMenuComponent.forceUpdate();
			this.setState({});
			this.forceUpdate();
		}, this.statUpdateSubscriptionId);
	}

	showMSG(data){
		if(this.growl) {
			this.growl.show(data);
		} else {
			console.log(data);
		}
	}

	componentDidMount() {
		window.globalStorage.error = (detail='', summary='Ошибка') => {
			// this.growl.show({severity: 'error', summary: summary, datail: detail})
			this.showMSG({severity: 'error', summary: detail})
		}
		window.globalStorage.warning = (detail='', summary= 'Внимание') => {
			// this.growl.show({severity: 'warning', summary: summary, datail: detail})
			this.showMSG({severity: 'warn', summary: detail})
		}
		window.globalStorage.success = (detail='', summary = 'Успешно') => {
			this.showMSG({severity: 'success', summary: summary, datail: detail})
		}
	}

	componentWillUnmount() {
		window.globalStorage.removeOnStatUpdate(this.statUpdateSubscriptionId);
	}

	onMenuClick(event) {
		this.menuClick = true;

		if (this.state.inlineProfileActive && !this.inlineProfileClick && this.isSlim()) {
			this.setState( {inlineProfileActive: false});
		}
		this.inlineProfileClick = false;
	}

	onMenuButtonClick(event) {
		this.setState({layoutMode: (this.isSlim() ? 'static' : 'slim')});
		event.preventDefault();
	}

	onMenuItemClick(event) {
		if (!event.item.items) {
			this.hideOverlayMenu();
        }

		if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
			this.setState({
				menuActive: false
			});
		}
	}

	onRootMenuItemClick(event) {
		this.setState({
			menuActive: !this.state.menuActive
		});

		event.originalEvent.preventDefault();
	}

	onDocumentClick(event) {
		if (!this.topbarItemClick) {
			this.setState({
				activeTopbarItem: null,
				topbarMenuActive: false
			});
		}

		if (!this.menuClick) {
			if (this.isHorizontal() || this.isSlim()) {
				this.setState({
					menuActive: false
				})
			}

			this.hideOverlayMenu();
		}

		if (this.state.inlineProfileActive && !this.inlineProfileClick && this.isSlim()) {
			this.setState( {inlineProfileActive: false});
		}

		if (!this.configClick) {
			this.setState({configDialogActive: false});
		}

		this.topbarItemClick = false;
		this.menuClick = false;
		this.configClick = false;
		this.inlineProfileClick = false;
	}

	hideOverlayMenu() {
		this.setState({
			overlayMenuActive: false,
			staticMenuMobileActive: false
		})
	}

	isHorizontal() {
		return this.state.layoutMode === 'horizontal';
	}

	isSlim() {
		return this.state.layoutMode === 'slim';
	}

	createMenu() {
		this.menuGrouped = [
			{
				label: 'Главная', icon: 'pi pi-fw pi-home',
				items: [
					{label: 'Главная', icon: 'pi pi-fw pi-home', to: '/'}
				]
			},
			{
				label: 'Заявки', icon: 'pi pi-fw pi-briefcase',
				items: [
					{label: 'Все', icon: 'pi pi-fw pi-list', to: '/delivery/list'},
					{label: 'В поиске', icon: 'pi pi-fw pi-spinner', to: '/delivery/list/unassigned', badge: this.stat.delivery.unassigned},
					{label: 'Зависшие', icon: 'pi pi-fw pi-exclamation-triangle', to: '/delivery/list/stuck', badge: this.stat.delivery.stuck},
					{label: 'В работе', icon: 'pi pi-fw pi-briefcase', to: '/delivery/list/progress', badge: this.stat.delivery.progress},
					{label: 'Просроченные', icon: 'pi pi-fw pi-clock', to: '/delivery/list/expired', badge: this.stat.delivery.expired},
					{label: 'Завершённые', icon: 'pi pi-fw pi-check-circle', to: '/delivery/list/finished', badge: this.stat.delivery.finished_today},
					{label: 'Отменённые', icon: 'pi pi-fw pi-times-circle', to: '/delivery/list/cancelled', badge: this.stat.delivery.cancelled_today}
				]
			},
			{
				label: 'Справочники', icon: 'pi pi-fw pi-list',
				items: [
					{label: 'Курьеры', icon: 'pi pi-fw pi-id-card', to: '/courier/list'},
					{label: 'Новые курьеры', icon: 'pi pi-fw pi-id-card', to: '/courier/list/new', badge: this.stat.courier.new},
					{label: 'На одобрение', icon: 'pi pi-fw pi-user-plus', to: '/courier/list/unapproved', badge: this.stat.courier.unapproved},
					{label: 'Карта курьеров', icon: 'pi pi-fw pi-id-card', to: '/courier/geoposition'},
					{label: 'Клиенты', icon: 'pi pi-fw pi-list', to: '/customer/list'},
				]
			}
		];

		this.menuUngrouped = [
			{
				label: 'Main Menu',
				icon: 'pi pi-fw pi-home',
				items: this.menuGrouped
			}
		];
	}

	getMenuComponent() {
		return <AppMenu ref={el => this.appMenuComponent = el}
				 model={this.state.grouped ? this.menuGrouped : this.menuUngrouped}
				 onMenuItemClick={this.onMenuItemClick}
				 onRootMenuItemClick={this.onRootMenuItemClick}
				 layoutMode={this.state.layoutMode}
				 active={this.state.menuActive}/>
	}

	render() {
		const layoutClassName = classNames('layout-wrapper', {
			'layout-horizontal': this.state.layoutMode === 'horizontal',
			'layout-overlay': this.state.layoutMode === 'overlay',
			'layout-static': this.state.layoutMode === 'static',
			'layout-slim': this.state.layoutMode === 'slim',
			'layout-static-inactive': this.state.staticMenuDesktopInactive,
			'layout-mobile-active': this.state.staticMenuMobileActive,
			'layout-overlay-active': this.state.overlayMenuActive,
			'layout-menu-dark': this.state.darkMenu,
			'layout-menu-light':!this.state.darkMenu
		});

		return (
			<HashRouter>
				<div className={layoutClassName} onClick={this.onDocumentClick}>
					<Growl ref={(el) => this.growl = el} />
					<AppTopbar topbarMenuActive={this.state.topbarMenuActive} onMenuButtonClick={this.onMenuButtonClick}/>

					<div className='layout-menu-container' onClick={this.onMenuClick}>
						<div className="layout-menu-logo">
							<button className="p-link">
								<img id="layout-menu-logo" src="assets/layout/images/easybox-logo-white.jpg" alt="easybox-logo"/>
							</button>
						</div>
						<div className="layout-menu-wrapper">
							<div className="menu-scroll-content">
								{this.getMenuComponent()}
							</div>
						</div>
					</div>

					<div className="layout-main">
						<div className="layout-content">
							<Route path="/" exact component={Dashboard2}/>

							<Route path="/delivery/list" exact component={OrderList}/>
							<Route path="/delivery/list/unassigned" exact render={()=><OrderListUnassigned statusFilter="unassigned" statusCaption="в поиске"/>}/>
							<Route path="/delivery/list/progress" exact render={()=><OrderListProgress statusFilter="progress" statusCaption="в работе"/>}/>
							<Route path="/delivery/list/finished" exact render={()=><OrderListFinished statusFilter="finished" statusCaption="завершённые" dateRangeFrom={new Date()}/>}/>
							<Route path="/delivery/list/stuck" exact render={()=><OrderListStuck statusFilter="stuck" statusCaption="зависшие"/>}/>
							<Route path="/delivery/list/cancelled" exact render={()=><OrderListCancelled statusFilter="cancelled" statusCaption="отменённые" dateRangeFrom={new Date()}/>}/>
							<Route path="/delivery/list/expired" exact render={()=><OrderListExpired statusFilter="expired" statusCaption="просроченные"/>}/>

							<Route path="/courier/list" exact component={CourierList}/>
							<Route path="/courier/list/new" exact component={CourierListNew}/>
							<Route path="/courier/list/unapproved" exact component={CourierListUnapproved} />
							<Route path="/courier/geoposition" exact component={CourierGeoPosition} />

							<Route path="/customer/list" exact component={CustomerList}/>

							<Route path="/customer/edit" component={CustomerEditPage} />
							<Route path="/courier/edit" component={CourierEditPage} />
							<Route path="/delivery/edit/:id" component={DeliveryEditPage} />
						</div>
					</div>

					<AppFooter/>

					{this.state.staticMenuMobileActive && <div className="layout-mask"></div>}
				</div>
			</HashRouter>
		);
	}
}

export default App;
