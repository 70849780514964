import React, {Component} from 'react';
import {Button} from "primereact/button";
import {DeliveryAddressItem} from "./DeliveryAddressItem";

export class DeliveryAddressList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addressList: {},
            isAddingInProgress: false
        };

        this.onAddNew = this.onAddNew.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount() {
        window.globalStorage.restService.getEntityList("delivery/address", {delivery: this.props.deliveryId, status: ""})
            .then((data) => this.setState({addressList: data.itemsList}))
            .catch((err) => window.globalStorage.error(err.message));
    }

    onAddNew() {
        let addrList = this.state.addressList;
        addrList[0] = {id: 0, delivery: this.props.deliveryId, arrival_date: window.globalStorage.formatter.formatDateTime(new Date()), payment_here: false};
        this.setState({addressList: addrList, isAddingInProgress: true});
    }

    onDelete(itemId) {
        if(0 === itemId) {
            this.deleteAddrItem(0);
        } else {
            window.globalStorage.restService.deleteEntity("delivery/address", itemId)
                .then(() => {this.deleteAddrItem(itemId)})
                .catch((err) => window.globalStorage.error(err.message));
        }
    }

    deleteAddrItem(id) {
        let addrList = this.state.addressList;
        delete addrList[id];
        let newState = {addressList: addrList};

        if (0 === id) {
            newState.isAddingInProgress = false;
        }

        this.setState(newState);
    }

    getList() {
        let list = Object.values(this.state.addressList).map((item) => {
            return <DeliveryAddressItem
                key={item.id}
                data={item}
                onDelete={() => {this.onDelete(item.id)}}
                onAddFinished={() => {this.setState({isAddingInProgress: false})}}
            />;
        });

        return <div>
            <div key="header" className="p-grid p-flex eba-address-list-head">
                <div className="p-col-12 p-lg-1 eba-address-th">&nbsp;</div>
                <div className="p-col-12 p-lg-4 eba-address-th">Когда, куда, кому</div>
                <div className="p-col-12 p-lg-4 eba-address-th">Комментарий</div>
                <div className="p-col-12 p-lg-2 eba-address-th">Интернет-магазин</div>
                <div className="p-col-12 p-lg-1 eba-address-th">&nbsp;</div>
            </div>

            {list}
        </div>;
    }

    render() {
        return <div className="card card-w-title p-col-12">
            <div className="eba-card-toolbar p-col-12">
                <div className="eba-toolbar-subleft">
                    <h2>Адреса</h2>
                </div>
                <div className="eba-toolbar-subright">
                    <Button label="Добавить" icon="pi pi-plus" iconPos="right" className="p-button-success" onClick={this.onAddNew} disabled={this.state.isAddingInProgress} />
                </div>
            </div>
            {this.getList()}
        </div>;
    }
}
