import React from 'react';
import {BaseListView} from "./BaseListView";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {SelectButton} from "primereact/selectbutton";
import {Checkbox} from "primereact/checkbox";
import {ToggleButton} from "primereact/togglebutton";
import MapView from "../../service/MapView";
import moment from "moment";

export class CourierList extends BaseListView {
    constructor() {
        super();

        this.entity.type = "courier";
        this.entity.caption = "Курьеры";

        this.statusMapping = {
            0: "новичок",
            1: "специалист",
            2: "профессионал"
        };

        this.formatCourierStatus = this.formatCourierStatus.bind(this);
    }

    componentDidMount() {
        this.setState({allSelected:true, showMAp: false});
        super.componentDidMount();
        this.setFilter({delivery_type: -1});
    }

    formatCourierStatus(row, field) {
        let status = row[field.field];
        if (null === status || !this.statusMapping.hasOwnProperty(status)) {
            return null;
        }

        return this.statusMapping[status];
    }

    formatDateTime(row,field){
        let date = row[field.field];
        return moment(date).format('Y-MM-DD hh:mm:ss');
    }


    formatDeliveryType(type) {
        return ['Пеший','Пеший+Авто','Авто'][type.delivery_type];
    }

    formatSelfEmployedStatus(status) {
        if(status==='YES') {
            var result = 'Да';
        } else if(status==='NO' || !status) {
            result = 'Нет';
        } else {
            result = '?'
        }
        return result;
    }

    toggleShowMap(state){
        this.setState({showMap: state},()=>{
            this.getPositions(state);
        });
    }

    titleActions(){
        return <>
            <ToggleButton
                checked={this.state.showMap}
                onChange={(e)=>this.toggleShowMap(e.value)}
                onLabel='Скрыть карту'
                offLabel='Показать карту'
                onIcon='pi pi-globe'
                offIcon='pi pi-globe'
            />
            {this.getMap()}
        </>;
    }

    getCheckedCouriers(){
        return this.state.courierList.filter(item=>item.checked);
    }

    getPositions(state = false){
        if(state || this.state.showMap) {
            const positions = this.getCheckedCouriers().map(item=>item.id);
            if(positions && positions.length) {
                window.globalStorage.restService.getCourierGeoPositionList(positions)
                    .then((data) => {
                        this.setState({geoPositions: data, updated: true})
                    })
                    .catch((err) => {
                        window.globalStorage.error(err.message);
                    });
            } else {
                this.setState({geoPositions: [], updated: true})
            }
        }
    }

    getMap(){
        // console.log('CUR:::',this.getCheckedCouriers());
        return this.state.showMap && <MapView
            geoPositions={this.state.geoPositions}
            onClose={e=>this.setState({showMap: false})}
        />
    }

    toggleAll(newState){
        const cl = this.state.courierList;
        cl.forEach((item,idx)=>{
            cl[idx].checked = newState;
        });
        this.setState({allSelected: newState, courierList: cl},()=>{
            this.getPositions();
        });
    }

    toggleRow(row, newState) {
        const cl = this.state.courierList;
        for(let i = 0; i < cl.length; i++) {
            if(cl[i].id === row.id) {
                cl[i].checked = newState;
            }
        }
        this.setState({courierList: cl},()=>{
            this.getPositions();
        });
    }

    getTable() {
        return <DataTable
            ref={el => this.tbl = el}
            value={this.state.courierList}
            paginator={true}
            rows={this.state.filter.limit}
            first={this.state.filter.offset}
            totalRecords={this.state.countItems}
            alwaysShowPaginator={false}
            lazy={true}
            onPage={(ev) => this.onPage(ev)}
            onRowDoubleClick={(ev) => {this.onRowDblClick(ev)}}
            emptyMessage="Список пуст">


            <Column field="checked"
                    header=<Checkbox checked={this.state.allSelected} onChange={e=>this.toggleAll(e.checked)} tooltip="Пометить/Снять пометку со всех"/>
                    body={row=><Checkbox checked={row.checked} onChange={e=>this.toggleRow(row,e.checked)}/>}
                    style={{width:'40px'}}
                    />

            <Column field="id" header="id" className="eba-id-column" style={{width:'60px'}}/>
            <Column field="fio" header="ФИО"/>
            <Column field="email" header="email" />
            <Column field="phone" header="телефон" style={{width:'110px'}}/>
            <Column field="balance" body={this.formatMoneyInCents} header="баланс" className="eba-numeric-column" style={{width:'100px'}}/>
            <Column field="access_delivery_legal" header=<span title="Безнал" style={{color: 'red'}}>&#8381;</span> style={{width:'30px'}} body={this.formatBooleanField} />
            <Column field="access_delivery_internet_shop" header=<span title="Выручка" style={{color: 'green'}}>&#8381;</span> style={{width:'30px'}} body={this.formatBooleanField} />
            {/*<Column field="city" header="город" style={{width:'100px'}} />*/}
            <Column field="completed_orders" header="выполнено" style={{width: '90px'}}/>
            {/*<Column field="late_orders" header="задержано" style={{width:'83px'}} />*/}
            <Column field="status" header="статус" style={{width:'108px'}} body={this.formatCourierStatus} />
            <Column field="self_employed_status" header=<span title="Cамозанятый">самоз</span> style={{width:'60px'}} body={(item) => this.formatSelfEmployedStatus(item.self_employed_status)} />
            <Column field="delivery_type" header="транспорт" style={{width:'100px'}} body={(v)=>this.formatDeliveryType(v)} />
            <Column field="created_on" header="создан" style={{width: '85px'}} body={this.formatDateTime}/>
            <Column field="update_date" header=<span title='Дата последней активности'>акт.</span> style={{width:'85px'}} body={this.formatDateTime}/>
            <Column field="blocked" header=<span title="Заблокирован">блк</span> style={{width:'30px'}} body={this.formatBooleanField} />

        </DataTable>
    }

    setType(e){
        if(e.value !== null) {
            this.setFilter({delivery_type: e.value})
            this.reloadList();
        }
    }

    filters() {
        return <div>
            {super.filters()}
            <SelectButton
                options={[
                        {label:'Все', value: -1},
                        {label:'Пешие', value: 0},
                        {label:'Пеший+Авто', value: 1},
                        {label:'Авто', value: 2}
                    ]}
                value={this.state.filter.delivery_type}
                onChange={(e)=>this.setType(e)}
                className='select-single-row'
            />
        </div>;
    }

    onReloadListDone() {
        this.toggleAll();
        this.initExcel();
    }

    excelPreformat(data){
        return [{
          columns: [
                {title:"id", width: {wpx: 80}},
                {title:"ФИО", width: {wch: 30}},
                {title:"email", width: {wch: 30}},
                {title:"телефон", width: {wch: 12}},
                {title:"баланс", width: {wch: 10}},
                {title:"безнал", width: {wch: 8}},
                {title:"выручка", width: {wch: 8}},
                {title:"выполнено", width: {wch: 14}},
                {title:"статус", width: {wch: 10}},
                {title:"самозанятый", width: {wch: 14}},
                {title:"транспорт", width: {wch: 14}},
                {title:"создан", width: {wch: 20}},
                {title:"обновлён", width: {wch: 20}},
                {title:"заблокирован", width: {wch: 20}},
            ],
            data: data.map( (item) => {
                return [
                    {value: item.id.toString()},
                    {value: item.fio || ''},
                    {value: item.email || ''},
                    {value: item.phone || ''},
                    {value: parseFloat(this.formatter.formatMoneyInCents(item.balance || '0')), numFmt: "0.00%"},
                    {value: item.access_delivery_legal ? 'Да' : 'Нет'},
                    {value: item.access_delivery_internet_shop ? 'Да' : 'Нет'},
                    {value: item.completed_orders},
                    {value: this.formatCourierStatus(item,{field:'status'})},
                    {value: this.formatSelfEmployedStatus(item.self_employed_status)},
                    {value: this.formatDeliveryType(item)},
                    {value: item.created_on || ''},
                    {value: item.update_date || ''},
                    {value: item.blocked ? 'Да' : 'Нет'},
                ]
            })
        }];
    }
}
