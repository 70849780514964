import React, {Component} from 'react';
import {Map, Placemark, YMaps} from "react-yandex-maps";

export default class MapView extends Component {
    constructor(props) {
        super(props);
        this.state = {geoPositions: []};
    }

    render() {
        // console.log('POS::',this.props.geoPositions);
        return <div className='image-view' style={{height:'530px'}}>
                <i className="layout-menuitem-icon pi pi-fw pi-times-circle image-view__close" onClick={()=>this.props.onClose()}/>
                <YMaps query={{apikey:'a73705c3-5b99-494a-bc39-6bb263ddcb0c'}}>
                    <Map defaultState={{ center: [55.751574, 37.573856], zoom: 9 }} height={530} width='100%'>
                        {this.props.geoPositions && this.props.geoPositions.length ?
                            this.props.geoPositions.map((item, index)=>
                                <Placemark
                                    key={'placemark_'+index}
                                    geometry={[item.latitude,item.longitude]}
                                    properties={{
                                        iconContent: item.id,
                                        balloonContentBody:
                                            `<div style='display:flex'>
                                                <div style="display: flex; flex-direction: column">
                                                    <div style="white-space: normal">${item.fio}</div>
                                                    <div style="white-space: normal">${item.coordinate_update_date}</div>
                                                </div>
                                            </div>`,
                                    }}
                                    options={{
                                    }}
                                    modules= {
                                        ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                    }
/*
                                    instanceRef={ ref => {
                                        ref && ref.balloon.open();
                                    }}
*/
                                />
                            ) : ''
                        }
                    </Map>
                </YMaps>
            </div>;
    }
}

//<image src='${this.item.avatarurl}' style='width:50px'/>
//
