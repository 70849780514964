import React, {Component} from 'react';

export class StatusLabel extends Component {
    constructor(props) {
        super(props);

        this.mappingDeliveryStatus = {
            0: {icon: "pi pi-spinner", color: "#999999", label: "в поиске"},
            1: {icon: "pi pi-briefcase", color: "#3366dd", label: "в работе"},
            2: {icon: "pi pi-check", color: "#66cc33", label: "выполнен"},
            3: {icon: "pi pi-times", color: "#ff6633", label: "отменён"}
        };
    }

    render() {
        if (null === this.props.status || 'undefined' === typeof(this.props.status)) {
            return null;
        }

        let statusConfig = this.mappingDeliveryStatus[this.props.status];

        return <div className={statusConfig.icon} style={{color: statusConfig.color}}>&nbsp;{statusConfig.label}</div>
    }
}
