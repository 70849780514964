import React, {Component} from 'react';
import {Button} from "primereact/button";
import {SearchBlock} from "../Element/SearchBlock";
import {DateRangeBlock} from "../Element/DateRangeBlock";
import {FormatterService} from "../../service/FormatterService";
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export class BaseListView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            courierList:[],
            countItems: 0,
            filter: {
                search: "",
                status: "",
                limit: 20,
                offset: 0,
                date_from: "",
                date_to: ""
            }
        };

        this.entity = {
            type: "",
            caption: ""
        };

        this.formatter = new FormatterService();
        this.formatMoneyInCents = this.formatMoneyInCents.bind(this);
        this.formatBooleanField = this.formatBooleanField.bind(this);
        this.excelSheetName = "Данные";
    }

    componentDidMount() {
        this.setFilter({
            status: this.props.statusFilter ? this.props.statusFilter : "",
            date_from: this.props.dateRangeFrom ? this.formatDate(this.props.dateRangeFrom) : ""
        })

        if(this.props.hasOwnProperty('itemsCount') && this.props.itemsCount) {
            this.setFilter({limit: this.props.itemsCount});
        }

        this.reloadList();
    }

    initExcel(){
        let filter = Object.assign({},this.state.filter,{limit:1000000, first: 0, offset: 0});
        console.log('filter>',filter);
        window.globalStorage.restService.getEntityList(this.entity.type,filter).then((data) => {
            // console.log('XLS DATA',filter,data);
            this.setState({all: data, subControls: data.itemsList && <ExcelFile element={<Button
                    icon="pi pi-file-excel"
                    style={{marginRight: '10px'}}
                    tooltip='Экспорт в Microsoft Excel'
                    tooltipOptions={{position:'left'}}
                    disabled={!data.itemsList}
                />}>
                    <ExcelSheet dataSet={this.excelPreformat(data.itemsList)} name={this.excelSheetName}/>
                </ExcelFile>
            });
        }).catch((err) => {
            window.globalStorage.error(err);
        });
    }

    excelPreformat(data){
        return [];
/*
        return [{
            columns: [
                {title:"id", width: {wpx: 80}},
                {title:"груз", width: {wch: 20}},
                {title:"объявленная ценность", width: {wch: 20}},
                {title:"цена для клиента", width: {wch: 20}},
                {title:"баллы", width: {wch: 10}},
                {title:"курьер", width: {wch: 40}},
                {title:"заработок курьера", width: {wch: 20}},
                {title:"создан", width: {wch: 24}},
                {title:"статус", width: {wch: 10}}
            ],
            data: data.map( item => {
                return [
                    {value: item.id.toString()},
                    {value: item.cargo_info},
                    {value: parseFloat(this.formatter.formatMoneyInCents(item.declared_price)), numFmt: "0.00%"},
                    {value: parseFloat(this.formatter.formatMoneyInCents(item.sum_customer_payment))},
                    {value: parseFloat(this.formatter.formatMoneyInCents(item.candy_wrapper_bonus)), style: {alignment: {horizontal: 'top'}}},
                    {value: item.courier_fio},
                    {value: parseFloat(this.formatter.formatMoneyInCents(item.sum_courier_income)), style: {alignment: {horizontal: 'top'}}},
                    // {value: new Date(item.created_on)},
                    {value: item.created_on},
                    {value: this.getStatusLabel(item.status), style: {font:{color:{rgb:['ffdddddd','ff0000ff','ff00ff00','ffff0000'][item.status]}}}},
                ]
            })
        }];
*/
    }
    onSearch(text) {
        this.setFilter({search: text});
        this.reloadList();
    }

    formatMoneyInCents(row, field) {
        return this.formatter.formatMoneyInCents(row[field.field]);
    }

    formatBooleanField(row, field) {
        let val = row[field.field];

        return val ? <i className="pi pi-check" style={{color: '#66cc33'}}></i> : <i className="pi pi-times" style={{color: '#ff6633'}}></i>;
    }

    formatDate(date) {
        let month = String(date.getMonth()+1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');
        return date.getFullYear() + "-" + month + "-" + day;
    }

    onFilterDate(from, to) {
        this.setFilter({
            date_from: from ? this.formatDate(from) + " 00:00:00" : "",
            date_to: to ? this.formatDate(to) + " 23:59:59" : ""
        });

        this.reloadList();
    }

    onRowDblClick(ev) {
        window.location = "/#/" + this.entity.type + "/edit/" + ev.data.id;
    }

    onPage(ev) {
        this.setFilter({first: ev.first, offset: ev.first});
        this.reloadList();
    }

    setFilter(data){
        this.setState({filter: Object.assign(this.state.filter, data)});
    }

    reloadList() {
        // console.log('RELOAD: ',this.state.filter);
        window.globalStorage.restService.getEntityList(this.entity.type, this.state.filter).then((data) => {
            // console.log('data',data);
            this.setState({countItems: data.itemsCount, courierList: data.itemsList});
            // console.log('STATE:',this.state);
        }).then(()=>{
            this.onReloadListDone();
        }).catch((err) => {
            window.globalStorage.error(err)
        });
    }

    onReloadListDone(){
    }

    getTable() {
        return "<p>Not implemented</p>\n";
    }

    getOverallCounter() {
        return this.state.countItems ? <div className="eba-footer-counter">Всего записей: {this.state.countItems}</div> : '';
    }

    filters() {
        return <div>
            <SearchBlock onReloadList={(text) => this.onSearch(text)} />

            <DateRangeBlock
                dateRangeFrom={this.props.dateRangeFrom}
                onReloadList={(from, to) => this.onFilterDate(from, to)}
            />
        </div>;
    }

    controls(){
        return <Button icon="pi pi-refresh" onClick={() => this.reloadList()} />;

    }

    titleActions() {
        return '';
    }

    render() {
        return (
            <div className="card card-w-title">
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h1><strong>{this.entity.caption}</strong> {this.props.statusCaption}</h1>
                    <div>{this.titleActions()}</div>
                </div>

                <div className="eba-card-toolbar p-col-12" style={{display:'flex',justifyContent:'space-between', alignItems: 'flex-end'}}>
                    {this.filters()}
                    <div>
                        { this.state.subControls && this.state.subControls }
                        { this.controls() }
                    </div>
                </div>

                <div className="p-col-12">
                    {this.getTable()}
                    {this.getOverallCounter()}
                </div>
            </div>
        );
    }
}
