import React, {Component} from 'react';
import {MassMsgSendBlock} from "./Element/MassMsgSendBlock";
import {NotesBlock} from "./Element/NotesBlock";
import {OrderListExpired} from "./EntityList/OrderListExpired";
import {FastSearchBlock} from "./Element/FastSearchBlock";
import {Coeff} from './Element/Coeff';

export class Dashboard2 extends Component {
    constructor() {
        super();

        // let stat = window.globalStorage.stat;
        // let mass_send = (stat.user
        //     && stat.user.hasOwnProperty('rights')
        //     && stat.user.rights
        //     && stat.user.rights.hasOwnProperty('mass_send')
        //     && stat.user.rights.mass_send
        // );

        this.state = {
            delivery_progress: window.globalStorage.stat.delivery.progress,
            delivery_finished_today: window.globalStorage.stat.delivery.finished_today,
            // massSend: mass_send,
            massSend: window.globalStorage.isMassSendAllowed(),
        };
        this.statUpdateSubscriptionId = 'dash2';

        // this.showError = this.showError.bind(this);
    }

    componentDidMount() {
        let that = this;
        window.globalStorage.addOnStatUpdate(function() {
console.log('OnStstUpdate in Dashboard2');

            let stat = window.globalStorage.stat;
            let mass_send = (stat.user
                && stat.user.hasOwnProperty('rights')
                && stat.user.rights
                && stat.user.rights.hasOwnProperty('mass_send')
                && stat.user.rights.mass_send
            );

            that.setState({delivery_progress: stat.delivery.progress,delivery_finished_today: stat.delivery.finished_today, massSend: mass_send});
            that.forceUpdate();
        }, this.statUpdateSubscriptionId);
    }

    componentWillUnmount() {
        window.globalStorage.removeOnStatUpdate(this.statUpdateSubscriptionId);
    }

    getMassSend() {
        if (!this.state.massSend) {
            return null;
        }

        return <div className="p-col-12 p-lg-5">
            <div className="card card-w-title">
                <MassMsgSendBlock/>
            </div>
        </div>;
    }

    getNotesBlock() {
        let classname = this.state.massSend ? "p-col-12 p-lg-7" : "p-col-12 p-lg-12";

        return <div className={classname}>
            <div className="card card-w-title">
                <NotesBlock/>
            </div>
        </div>
    }

    render() {
        return <div className="layout-dashboard">
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-6 p-xl-3" >
                    <div className="overview-box checkin" onClick={() => {window.location = "/#/delivery/list/finished"}}>
                        <i className="overview-icon pi pi-check-circle"/>
                        <div className="overview-numbers">
                            {this.state.delivery_finished_today}
                        </div>
                        <div className="overview-subinfo">
                            Выполнено заказов за день
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-lg-6 p-xl-3">
                    <div className="overview-box views" onClick={() => {window.location = "/#/delivery/list/progress"}}>
                        <i className="overview-icon pi pi-briefcase"/>
                        {/*<span className="overview-title">Заявок в работе</span>*/}
                        <div className="overview-numbers">
                            {this.state.delivery_progress}
                        </div>
                        <div className="overview-subinfo">
                            Заявок в работе
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-lg-6 p-xl-3">
                    <FastSearchBlock showError={this.showError} />
                </div>

                { this.state.massSend &&
                    <div className="p-col-12 p-lg-6 p-xl-3">
                        <div className="overview-box users">
                            <div className="overview-numbers">
                                <Coeff/>
                            </div>
                            <div className="overview-subinfo">
                                Коэффициент на тарифы
                            </div>
                        </div>
                    </div>
                }

                {this.getNotesBlock()}
                {this.getMassSend()}

                <div className="p-col-12 p-lg-12">
                    <OrderListExpired statusFilter="expired" statusCaption="просроченные" itemsCount={10}/>
                </div>
            </div>
        </div>
    }
}
