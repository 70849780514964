import React from 'react';
import {BaseListView} from "./BaseListView";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {StatusLabel} from "../Element/StatusLabel";
import {YesNo} from "../Element/FormBlock/YesNo";
// import {Button} from "primereact/button";
// import ReactExport from "react-data-export";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


export class OrderList extends BaseListView {
    constructor(props) {
        super(props);

        this.entity.type = "delivery";
        this.entity.caption = window.globalStorage.getEntityNameMulti(this.entity.type);
        this.formatStatusField = this.formatStatusField.bind(this);
        this.excelSheetName = "Заявки";
    }

    formatStatusField(row, field) {
        let val = row[field.field];
        return <StatusLabel status={val} />;
    }

    getStatusLabel(idx) {
        return ["в поиске", "в работе", "выполнен", "отменён"][idx] || '';
    }

    onReloadListDone() {
        this.initExcel();
    }


    // initExcel(){
    //     let filter = Object.assign({},this.state.filter,{limit:1000000});
    //     window.globalStorage.restService.getEntityList(this.entity.type,filter).then((data) => {
    //         // console.log('XLS DATA',filter,data);
    //         this.setState({all: data, subControls: data.itemsList && <ExcelFile element={<Button
    //                 icon="pi pi-file-excel"
    //                 style={{marginRight: '10px'}}
    //                 tooltip='Экспорт в Microsoft Excel'
    //                 tooltipOptions={{position:'left'}}
    //                 disabled={!data.itemsList}
    //             />}>
    //                 <ExcelSheet dataSet={this.excelPreformat(data.itemsList)} name={this.excelSheetName}/>
    //             </ExcelFile>
    //         });
    //     }).catch((err) => {
    //         window.globalStorage.error(err);
    //     });
    // }

    excelPreformat(data){
        return [{
            columns: [
                {title:"id", width: {wpx: 80}},
                {title:"груз", width: {wch: 20}},
                {title:"объявленная ценность", width: {wch: 20}},
                {title:"цена для клиента", width: {wch: 20}},
                {title:"баллы", width: {wch: 10}},
                {title:"курьер", width: {wch: 40}},
                {title:"заработок курьера", width: {wch: 20}},
                {title:"создан", width: {wch: 24}},
                {title:"статус", width: {wch: 10}},
            ],
            data: data.map( item => {
                return [
                    {value: item.id.toString()},
                    {value: item.cargo_info},
                    {value: parseFloat(this.formatter.formatMoneyInCents(item.declared_price)), numFmt: "0.00%"},
                    {value: parseFloat(this.formatter.formatMoneyInCents(item.sum_customer_payment))},
                    {value: parseFloat(this.formatter.formatMoneyInCents(item.candy_wrapper_bonus)), style: {alignment: {horizontal: 'top'}}},
                    {value: item.courier_fio},
                    {value: parseFloat(this.formatter.formatMoneyInCents(item.sum_courier_income)), style: {alignment: {horizontal: 'top'}}},
                    // {value: new Date(item.created_on)},
                    {value: item.created_on},
                    {value: this.getStatusLabel(item.status), style: {font:{color:{rgb:['ffdddddd','ff0000ff','ff00ff00','ffff0000'][item.status]}}}},
                ]
            })
        }];
    }

    getTable() {
        // console.log('LIST',this.state);
        return (
            <DataTable
                ref={el => this.tbl = el}
                value={this.state.courierList}
                paginator={true}
                rows={this.state.filter.limit}
                first={this.state.filter.offset}
                totalRecords={this.state.countItems}
                alwaysShowPaginator={false}
                lazy={true}
                onPage={(ev) => this.onPage(ev)}
                onRowDoubleClick={(ev) => {this.onRowDblClick(ev)}}
                emptyMessage="Список пуст"
            >
                <Column field="id" header="id" className="eba-id-column"/>
                <Column field="cargo_info" header="груз"/>
                <Column field="declared_price" body={this.formatMoneyInCents} header="объявленная ценность" className="eba-numeric-column" />
                <Column field="sum_customer_payment" body={this.formatMoneyInCents} header="цена для клиента" className="eba-numeric-column" />
                <Column field="candy_wrapper_bonus" header="баллы" className="eba-numeric-column" style={{width: '80px'}} body={this.formatMoneyInCents} />
                <Column field="courier_fio" header="курьер"/>
                <Column field="sum_courier_income" header="заработок курьера" body={this.formatMoneyInCents} className="eba-numeric-column" />
                <Column field="created_on" header="создан" style={{width: '122px'}} />
                <Column field="status" header="статус" style={{width: '110px'}} body={this.formatStatusField} />
                <Column field="rec_id" header="чек" style={{width: '30px'}} body={(r,f) => <YesNo state={r[f.field]}/>}/>

            </DataTable>
        );
    }
}
