import React, {Component} from 'react';
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";

export class MassMsgSendBlock extends Component {
    send() {
        let txt = this.massMsgTextarea.element.value;
        if (0 === txt.length) {
            return;
        }

        if (2048 < txt.length) {
            window.globalStorage.error("Слишком длинное сообщение (более 2048 символов). Не будет отправлено.");
            return;
        }

        let that = this;
        window.globalStorage.restService.massMsg(txt)
            .then(function() {
                that.massMsgTextarea.element.value = "";
                window.globalStorage.success("Сообщение поставлено в очередь на отправку курьерам");
            }).catch((err) => {window.globalStorage.error(err)});
    }

    render() {
        return <div>
            <h1>Сообщение всем курьерам</h1>
            <InputTextarea ref={el => this.massMsgTextarea = el} rows={4} cols={52} placeholder="Сообщение всем курьерам" autoResize={true} />
            <br/>
            <Button label="Отправить" icon="pi pi-chevron-circle-right" iconPos="right" onClick={()=>{this.send()}} />
        </div>
    }
}
